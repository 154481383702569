export const DEFAULT_CURRENCY = "EUR";
export const GHS = "GHS";
export const USD = "USD";
export const EUR = "EUR";
export const KES = "KES";
export const XOF = "XOF";
export const NGN = "NGN";

export const CURRENCY_CHOICES = {
  [GHS]: "GHS",
  [USD]: "USD",
  [EUR]: "EUR",
  [KES]: "KES",
  [NGN]: "NGN",
  [XOF]: "XOF",
};

export const DUE = "D";
export const PAID = "P";
export const RECEIVED = "R";
export const REFUTED = "U";
export const RESOLVED = "V";

export const COMPANY_INVOICE_STATUS_CHOICES = {
  [DUE]: "Due",
  [PAID]: "Paid",
  [RECEIVED]: "Received",
  [REFUTED]: "Refuted",
  [RESOLVED]: "Resolved",
};

export const INTERNATIONAL_CARD = "I";
export const LOCAL_CARD = "L";
export const MOBILE_MONEY = "M";

export const PAYMENT_METHOD_CHOICES = {
  [INTERNATIONAL_CARD]: "International card",
  [LOCAL_CARD]: "Local card",
  [MOBILE_MONEY]: "Mobile money",
};

export const INITIALIZED = "I";
export const PENDING = "P";
export const SUCCESSFUL = "S";
export const FAILED = "F";
export const CANCELLED = "C";

export const POLICY_PAYMENT_STATUS = {
  [INITIALIZED]: "Initialized",
  [PENDING]: "Pending",
  [SUCCESSFUL]: "Successful",
  [FAILED]: "Failed",
  [CANCELLED]: "Cancelled",
};

export const MTN = "M";
export const AIRTEL_TIGO = "A";
export const VODAFONE = "V";

export const MOBILE_MONEY_TYPE_CHOICES = {
  [MTN]: "MTN",
  [AIRTEL_TIGO]: "Airtel Tigo",
  [VODAFONE]: "Vodafone",
};
