import React from 'react'
import { Switch } from 'react-router-dom'
import PropTypes from 'prop-types'

import PrivateRoute from 'hocs/PrivateRoute'
import Home from './Home'

const UserRoutes = props => (
  <>
    <Switch>
      <PrivateRoute exact path={`${props.match.path}`} component={Home} />
    </Switch>
  </>
)

UserRoutes.propTypes = {
  match: PropTypes.object
}

export default UserRoutes
