import React, { useEffect, useState } from 'react'
import { Space, Breadcrumb, Modal } from 'antd'
import FilterTable from 'components/Forms/searchFilter'
import axios from 'axios'

import { getHeaders, transactionsUrl, errorNotification } from '../../../config'
import PageLoader from 'components/Layouts/PageLoader'
import { POLICY_STATUS_CHOICES } from 'app-constants/policies'
import Detail from './Detail'

const Home = props => {
  const [transactions, setTransactions] = useState([])

  // eslint-disable-next-line
  const [meta, setMeta] = useState({})
  const [loading, setloading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [current, setCurrent] = useState(null)

  const getTransactions = () => {
    setloading(true)
    axios
      .get(transactionsUrl, { headers: getHeaders() })
      .then(res => {
        setloading(false)
        setTransactions(
          res.data.results.map(tran => {
            return {
              ...tran,
              status: POLICY_STATUS_CHOICES[tran.status],
              companyName:
                tran.policy && tran.policy.company && tran.policy.company.name,
              companyId:
                tran.policy && tran.policy.company && tran.policy.company.id,
              companyLogo:
                tran.policy &&
                tran.policy.company &&
                tran.policy.company.logoUrl,
              policyType: `${tran.moduleType}`,
              policyId: tran.policy && tran.policy.id,
              amount: `${tran.currency} ${tran.amount}`,
              paymentChannel: tran.method,
              reference: tran.reference,
              fullName: tran.user && tran.user.fullName,
              email: tran.user && tran.user.email,
              firstName:
                tran.policy && tran.policy.user && tran.policy.user.firstName,
              lastName:
                tran.policy && tran.policy.user && tran.policy.user.lastName,
              duration: tran.policy && `${tran.policy.duration} Months`,
              timestamp: `${new Date(
                tran.updatedAt
              ).toDateString()} | ${new Date(tran.updatedAt).toTimeString()}`
            }
          })
        )
        setMeta({
          previous: res.data.previous,
          next: res.data.next,
          total: res.data.count
        })
        // console.log(res.data)
      })
      .catch(err => {
        setloading(false)
        errorNotification('Error fetching transactions')
      })
  }

  useEffect(() => {
    getTransactions()
  }, [])

  const columns = [
    {
      title: 'User Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      width: 120
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 120
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      width: 120
    },
    {
      title: 'Policy',
      dataIndex: 'policyType',
      key: 'policyType',
      width: 120
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 120
    },
    {
      title: 'Company',
      dataIndex: 'companyName',
      key: 'companyName',
      width: 120,
      render: (text, record) => (
        <Space size="middle">
          {/* <img src={record.companyLogo} height={50} width={50} /> */}
          <span>{record.companyName}</span>
          {/* eslint-disable-next-line */}
          {/* <a href="#" onClick={handleDetail.bind(this, record)}>
            View
          </a> */}
        </Space>
      )
    },
    {
      title: 'Reference',
      dataIndex: 'reference',
      key: 'reference',
      width: 120
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 80,
      render: (text, record) => (
        <Space size="middle">
          {/* <span onClick={handleDetail.bind(this, record)}>View</span> */}
          {/* eslint-disable-next-line */}
          {/* <a href="#" onClick={handleDetail.bind(this, record)}>
            View
          </a> */}
        </Space>
      )
    }
  ]

  const handleCurrent = curr => {
    setShowModal(true)
    setCurrent(curr)
  }

  return (
    <PageLoader className="container" loading={loading}>
      <div className="row">
        <div className="col-lg-12">
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>Dashboard</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>Transactions</span>
            </Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          <div className="card mb-4rem">
            <div className="card-header">
              <div className="utils__title">
                <span>Transactions</span>
              </div>
            </div>

            <div className="card-body">
              <FilterTable
                columns={columns}
                dataSource={transactions}
                onRow={handleCurrent}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Transaction Details"
        centered
        visible={showModal}
        onOk={() => {
          setShowModal(false)
          setCurrent(null)
        }}
        onCancel={() => {
          setShowModal(false)
          setCurrent(null)
        }}
        footer={null}
      >
        {showModal && current ? <Detail policy={current} /> : null}
      </Modal>
    </PageLoader>
  )
}

export default Home
