import React from 'react'
// import Page from 'components/LayoutComponents/Page'
import Helmet from 'react-helmet'
import Products from './Products'
import * as c from 'app-constants/accounts'

class Home extends React.Component {
  static defaultProps = {
    pathName: 'Travel Products',
    roles: [c.MANAGEMENT, c.FINANCE, c.SALES]
  }

  render() {
    // const props = this.props
    return (
      <div>
        <Helmet title="Travel Products" />
        <Products />
      </div>
    )
  }
}

export default Home
