import BasicInput from 'components/Forms/BasicInput'
import DatePickerInput from 'components/Forms/DatePickerInput'
import SelectInput from 'components/Forms/SelectInput'
import {
  promoUrl,
  successNotification,
  moduleUrl,
  getHeaders
} from '../../../config'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
// import moment from 'moment'
import { Form } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
// import { Link } from 'react-router-dom'

const Create = props => {
  const [promo, setPromo] = useState({
    title: '',
    promoCode: '',
    discountPercentage: '',
    startDate: new Date(),
    endDate: new Date(),
    policyTypes: []
  })
  const [errorMessage, setErrorMessage] = useState({})
  const [policyOptions, setpolicyOptions] = useState([])
  const [loading, setLoading] = useState(false)

  const removeErrorMessage = stateName => {
    // remove form field errors
    if (errorMessage[stateName]) {
      setErrorMessage({
        ...errorMessage,
        [stateName]: null,
        nonFieldErrors: null
      })
    }
  }

  const handleFieldUpdate = stateName => event => {
    setPromo({ ...promo, [stateName]: event.target.value })

    // remove form field errors
    removeErrorMessage(stateName)
  }

  const handleSelectUpdate = stateName => value => {
    if (value) {
      setPromo({ ...promo, [stateName]: value })
    } else {
      setPromo({ ...promo, [stateName]: '' })
    }
    console.log(promo[stateName])

    // remove form field errors
    removeErrorMessage(stateName)
  }

  const handleDateUpdate = stateName => date => {
    setPromo({ ...promo, [stateName]: date })

    // remove form field errors
    removeErrorMessage(stateName)
  }

  const handleSubmit = async () => {
    // let data = {
    //   ...promo,
    //   startDate: promo.startDate
    //     ? moment(promo.startDate).format('YYYY-MM-DD')
    //     : '',
    //   endDate: promo.endDate ? moment(promo.endDate).format('YYYY-MM-DD') : ''
    // }

    setLoading(true)

    axios
      .post(`${promoUrl}`, promo, {
        headers: getHeaders()
      })
      .then(res => {
        props.handleCancel()
        successNotification('Successful')
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        if (err.response) {
          err.response.status === 400
            ? setErrorMessage({
                ...err.response.data,
                nonFieldErrors: [
                  err.response.data?.message
                  // "Kindly make sure you've completed the form"
                ]
              })
            : setErrorMessage({
                ...errorMessage,
                nonFieldErrors: ['Oops, server Error! Retry']
              })
        } else {
          setErrorMessage({
            ...errorMessage,
            nonFieldErrors: ['Error Connecting to Server, Retry.']
          })
        }
      })
  }

  const getPolicyOptions = () => {
    axios.get(moduleUrl, { headers: getHeaders() }).then(res => {
      setpolicyOptions(
        res.data.results.map(m => {
          return { ...m, value: m.id, label: m.title }
        })
      )
    })
  }

  useEffect(() => {
    getPolicyOptions()
  }, [])

  return (
    <div>
      <Form layout="vertical">
        <div className="row">
          <div className="col-md-6 mb-4">
            <BasicInput
              label="Title"
              value={promo.title}
              name={'title'}
              placeholder="black friday promo code"
              handleChange={handleFieldUpdate('title')}
              required={true}
            />
            {errorMessage.title
              ? errorMessage.title.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
                ))
              : null}
          </div>
          <div className="col-md-6 mb-4">
            <BasicInput
              label="Promo Code"
              value={promo.promoCode}
              name={'promo code'}
              placeholder="eg: BLACKFRIDAY"
              handleChange={handleFieldUpdate('promoCode')}
              required={true}
            />
            {errorMessage.promoCode
              ? errorMessage.promoCode.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
                ))
              : null}
          </div>

          <div className="col-md-6 mb-4">
            <BasicInput
              label="Discount Percentage"
              value={promo.discountPercentage}
              name={'discount'}
              placeholder="eg: 30"
              handleChange={handleFieldUpdate('discountPercentage')}
              required={true}
            />
            {errorMessage.discountPercentage
              ? errorMessage.discountPercentage.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
                ))
              : null}
          </div>
          <div className="col-md-6 mb-4">
            <DatePickerInput
              label="Starting Date"
              value={promo.startDate}
              handleChange={handleDateUpdate('startDate')}
              name="starting date"
              required={true}
            />
            {errorMessage.startDate
              ? errorMessage.startDate.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
                ))
              : null}
          </div>

          <div className="col-md-6 mb-4">
            <DatePickerInput
              label="Ending Date"
              value={promo.endDate}
              handleChange={handleDateUpdate('endDate')}
              name="ending date"
              required={true}
            />
            {errorMessage.endDate
              ? errorMessage.endDate.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
                ))
              : null}
          </div>

          <div className="col-md-6 mb-4">
            <SelectInput
              label={'Policies Applied'}
              value={promo.policyTypes}
              name={'policies'}
              optionList={policyOptions}
              onChange={handleSelectUpdate('policyTypes')}
              mode={'multiple'}
              placeholder="select policies"
              required={true}
            />
            {errorMessage.policyTypes
              ? errorMessage.policyTypes.map((err, i) => (
                  <p style={{ color: 'red', marginTop: '1em' }} key={i}>
                    {err}
                  </p>
                ))
              : null}
          </div>

          <div className="col-md-12 mt-4">
            <div className="row">
              <div
                className="col"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {/* <button
                  className="btn btn-outline-secondary btn-sm"
                  onClick={props.handleCancel}
                >
                  Close
                </button> */}

                <button
                  className="btn btn--width-lng btn--gradient-primary"
                  onClick={() => {
                    handleSubmit()
                  }}
                >
                  Add Promo Code {loading ? <LoadingOutlined /> : null}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}

export default Create
