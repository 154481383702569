import React from 'react'
import { Space, Modal, Breadcrumb } from 'antd'
import axios from 'axios/index'
import 'c3/c3.css'
import './style.scss'
import Detail from './Detail'
import { clientsUrl, getHeaders } from '../../../../config'
import { withRouter } from 'react-router-dom'
import FilterTable from 'components/Forms/searchFilter'
import PageLoader from 'components/Layouts/PageLoader'

class Client extends React.Component {
  state = {
    clientsTableData: [],
    showClientModal: false,
    selectedClient: null,
    loading: false
  }

  // update tables
  updateTableResults() {
    // const { dispatch } = this.props
    this.setState({ loading: true })
    axios
      .get(clientsUrl, { headers: getHeaders() })
      .then(res => {
        this.setState({ loading: false })
        // motor request
        let motor_Clients = res.data.results.map(dep => {
          return {
            ...dep,
            confirmedText: dep.confirmed ? 'Yes' : 'No',
            createdAt: new Date(dep.createdOn).toDateString()
          }
        })
        // end motor request

        this.setState({
          clientsTableData: motor_Clients
        })
        console.log('DATA SET')
      })
      .catch(error => {
        this.setState({ loading: false })
        console.log(error)
      })
    // .then(() => {
    //   // dispatch(setLoading(false));
    // })
  }
  // end update tables

  componentDidMount() {
    this.updateTableResults()
  }

  handleShowClientModal = () => {
    console.log('Show modal')
    this.setState({
      showClientModal: true
    })
  }

  handleClientOk = e => {
    console.log(e)
    this.setState({
      showClientModal: false
    })
  }

  handleClientCancel = e => {
    console.log(e)
    this.setState(
      {
        showClientModal: false
      },
      () => this.updateTableResults()
    )
  }

  handleInputChange = stateName => event => {
    this.setState({ [stateName]: event.target.value })
  }

  render() {
    if (this.props.isLoading) return null

    let clientDetailRow = record => {
      this.setState({ selectedClient: record, showClientModal: true })
    }

    let { clientsTableData } = this.state

    const columns = [
      {
        title: 'Date Added',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 120
      },
      {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName',
        width: 120
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName',
        width: 120
      },
      {
        title: 'Phone Number',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        width: 120
      },
      {
        title: 'Email Address',
        dataIndex: 'email',
        key: 'email',
        width: 150
        // fixed: 'left'
      },

      {
        title: 'Confirmed',
        dataIndex: 'confirmedText',
        key: 'confirmedText',
        width: 90
      },

      {
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 80,
        render: (text, record) => (
          <Space size="middle">
            <span style={{ color: 'blue', cursor: 'pointer' }}>View</span>
          </Space>
        )
      }
    ]

    return (
      <PageLoader className="row main-page" loading={this.state.loading}>
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            {/* <HomeOutlined /> */}
            <span>Home</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Clients</Breadcrumb.Item>
        </Breadcrumb>
        <br />
        <br />
        <Modal
          title="Client Details"
          centered
          visible={this.state.showClientModal}
          onOk={this.handleClientOk}
          onCancel={this.handleClientCancel}
          footer={null}
        >
          {this.state.showClientModal ? (
            <Detail
              handleCancel={this.handleClientCancel}
              client={this.state.selectedClient}
            />
          ) : null}
        </Modal>

        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <div className="utils__title">
                <span>All Clients</span>
              </div>
            </div>
            <div className="card-body">
              <div style={{ marginBottom: 16 }}>
                {/* <button
                  className="btn_normal btn_gradient btn_md"
                  onClick={() =>
                    this.props.history.push('/buy-motor-insurance')
                  }
                >
                  Buy New Policy
                </button> */}
              </div>

              <FilterTable
                columns={columns}
                dataSource={clientsTableData}
                onRow={clientDetailRow}
              />
            </div>
          </div>
        </div>
      </PageLoader>
    )
  }
}

export default withRouter(Client)
