import React from 'react'
// import Page from "components/LayoutComponents/Page";
import Helmet from 'react-helmet'
import Company from './company'
import * as c from 'app-constants/accounts'

class Companies extends React.Component {
  static defaultProps = {
    pathName: 'Companies',
    roles: [c.MANAGEMENT, c.FINANCE, c.SALES]
  }

  render() {
    return (
      <div>
        <Helmet title="Companies" />
        <Company />
      </div>
    )
  }
}

export default Companies
