import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Menu, Button } from 'antd'
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  PieChartOutlined,
  TransactionOutlined,
  SafetyCertificateOutlined,
  // WalletOutlined,
  BookOutlined,
  UsergroupAddOutlined,
  UserSwitchOutlined,
  SettingOutlined,
  BuildOutlined,
  FolderOutlined,
  GiftOutlined
} from '@ant-design/icons'
import bqLogo from 'img/bestquote-logo.png'

// const { SubMenu } = Menu

const DashboardSidebar = props => {
  const [collapsed, setCollapsed] = useState()

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }

  return (
    <React.Fragment>
      <div className="dashboard_sidebar">
        <img
          src={bqLogo}
          alt="logo"
          className="dashboard_sidebar_logo"
          onClick={() => props.history.push('/')}
        />

        <Menu
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub4']}
          mode="inline"
          theme="light"
          inlineCollapsed={collapsed}
          className="dashboard_sidebar_menu"
        >
          <Menu.Item
            key="1"
            icon={<PieChartOutlined />}
            onClick={() => props.history.push('/')}
          >
            Overview
          </Menu.Item>
          <Menu.Item
            icon={<TransactionOutlined />}
            onClick={() => props.history.push('/transactions')}
          >
            Transactions
          </Menu.Item>
          <Menu.Item
            icon={<UsergroupAddOutlined />}
            onClick={() => props.history.push('/users')}
          >
            User Accounts
          </Menu.Item>
          <Menu.Item
            icon={<UserSwitchOutlined />}
            onClick={() => props.history.push('/clients')}
          >
            Clients
          </Menu.Item>
          <Menu.Item
            icon={<SafetyCertificateOutlined />}
            onClick={() => props.history.push('/motor')}
          >
            Motor Policies
          </Menu.Item>

          <Menu.Item
            icon={<SafetyCertificateOutlined />}
            onClick={() => props.history.push('/travel')}
          >
            Travel Policies
          </Menu.Item>

          <Menu.Item
            icon={<BookOutlined />}
            onClick={() => props.history.push('/travel-products')}
          >
            Travel Products
          </Menu.Item>

          <Menu.Item
            icon={<SafetyCertificateOutlined />}
            onClick={() => props.history.push('/property')}
          >
            Property Policies
          </Menu.Item>
          <Menu.Item
            icon={<BuildOutlined />}
            onClick={() => props.history.push('/companies')}
          >
            Companies
          </Menu.Item>
          <Menu.Item
            icon={<FolderOutlined />}
            onClick={() => props.history.push('/emails')}
          >
            Subscription Emails
          </Menu.Item>
          <Menu.Item
            icon={<GiftOutlined />}
            onClick={() => props.history.push('/promo-codes')}
          >
            Promo Codes
          </Menu.Item>
          <Menu.Item
            key="22"
            icon={<SettingOutlined />}
            onClick={() => props.history.push('/account-settings')}
          >
            Account Settings
          </Menu.Item>
        </Menu>
        <Button
          type="primary"
          onClick={toggleCollapsed}
          className="dashboard_sidebar_btn"
        >
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined
          )}
        </Button>
      </div>
    </React.Fragment>
  )
}

DashboardSidebar.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object
}

export default withRouter(DashboardSidebar)
