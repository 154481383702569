import React, { Component } from 'react'
// import axios from 'axios'
// import { Link } from 'react-router-dom'

// import * as p from 'app-constants/policies'
// import * as pc from 'app-constants/products'

class Detail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      company: this.props.company
    }
  }

  render() {
    let { company } = this.props

    return (
      <div>
        <form>
          {/* <div className="text-center mb-2">
            <div className="alert alert-dark">
              <span>PAYMENT STATUS: </span>{' '}
              <span
                className={`badge badge-${
                  company.paymentColor === 'green' ? 'success' : 'danger'
                }`}
              >
                {company.payment}
              </span>
            </div>
          </div>
          <br /> */}
          <div className="row">
            <div className="col-md-6 mb-4">
              <label className="mb-1">Name</label>
              <input
                type="text"
                disabled
                value={company.name}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="mb-1">About</label>
              <textarea
                disabled
                value={company.about}
                className="form-control"
                aria-describedby="basic-addon1"
              ></textarea>
            </div>

            <div className="col-md-6 mb-4">
              <label className="mb-1">Email Address</label>
              <input
                type="text"
                disabled
                value={company.email}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            <div className="col-md-6 mb-4">
              <label className="mb-1">Office Address</label>
              <input
                type="text"
                disabled
                value={company.officeAddress}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="mb-1">Office Phone</label>
              <input
                type="text"
                disabled
                value={company.officePhone}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            <div className="col-md-6 mb-4">
              <label className="mb-1">Postal Address</label>
              <input
                type="text"
                disabled
                value={company.postalAddress}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="mb-1">Agreement Accepted</label>
              <input
                type="text"
                disabled
                value={company.agreementAccepted ? 'YES' : 'NO'}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            {company.agreementAccepted ? (
              <div className="col-md-6 mb-4">
                <label className="mb-1">Agreement Document</label>
                <div>
                  <a
                    href={company.agreementDocument}
                    target="blank"
                    style={{ color: 'blue' }}
                  >
                    Here
                  </a>
                </div>
              </div>
            ) : null}

            {/* <div className="col-md-6 mb-4">
              <label className="mb-1">Agent Name</label>
              <input
                type="text"
                disabled
                value={
                  company.agent
                    ? `${company.agent.firstName} ${company.agent.lastName}`
                    : 'N/A'
                }
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div> */}

            <div className="col-md-6 mb-4">
              <label className="mb-1">Date Added</label>
              <input
                type="text"
                disabled
                value={`${new Date(
                  company.createdOn
                ).toDateString()} | ${new Date(
                  company.createdOn
                ).toTimeString()}`}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            <div className="col-md-6 mb-4">
              <label className="mb-1">Date Updated</label>
              <input
                type="text"
                disabled
                value={`${new Date(
                  company.lastUpdate
                ).toDateString()} | ${new Date(
                  company.lastUpdate
                ).toTimeString()}`}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            {/* <div className="col-md-12 mt-4">
              <div className="row">
                <div
                  className="col"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <button
                    className="btn btn-outline-secondary btn-sm"
                    onClick={this.props.handleCancel}
                  >
                    Close
                  </button>
                  {[p.PAID, p.ACTIVE].includes(policy.status) ? (
                    <Link
                      to={{
                        pathname: `/claims/motor/new/${policy.policyId}`
                      }}
                      className="navbar__link"
                    >
                      <button className="btn btn--width-lng btn--gradient-primary">
                        Make Claim
                      </button>
                    </Link>
                  ) : (
                    <Link
                      to={`/buy-motor-insurance/${policy.policyId}/buy`}
                      className="navbar__link"
                    >
                      <button className="btn btn--width-lng btn--gradient-primary">
                        Make Payment
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div> */}
          </div>
        </form>
      </div>
    )
  }
}

export default Detail
