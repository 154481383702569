import React, { Component } from 'react'
import axios from 'axios'
// import { connect } from 'react-redux'

import * as pc from 'app-constants/products'
import * as yc from 'app-constants/payments'
import { companiesUrl, getHeaders, travelProductsUrl } from '../../../../config'

// const mapStateToProps = state => {
//   return {
//     company_id: state.app.userState.branch.company.id
//   }
// }

// @connect(mapStateToProps)
class Create extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: -1,
      type: pc.TRAVEL,
      name: '',
      description: '',
      currency: '',
      company_id: '',
      companies: [],
      travelInfo: {
        region: '',
        exceptions: '',
        isSingleTrip: '',
        isFamily: '',
        lostBaggage: {
          currency: '',
          amount: ''
        },
        medicalLimit: {
          currency: '',
          amount: ''
        },
        sumAssured: {
          currency: '',
          amount: ''
        },
        maxDuration: '',
        numberOfTravellers: {},
        travelCoverBreakdownSet: []
      },
      travelPremiums: [],
      isDisabled: true
    }
  }

  handleInput = stateName => event => {
    if (stateName.length === 1) {
      this.setState({
        [stateName[0]]: event.target.value
      })
    } else if (stateName.length === 2) {
      this.setState({
        [stateName[0]]: {
          ...this.state[stateName[0]],
          [stateName[1]]: event.target.value
        }
      })
    } else if (stateName.length === 3) {
      this.setState({
        [stateName[0]]: {
          ...this.state[stateName[0]],
          [stateName[1]]: {
            ...this.state[stateName[1]],
            [stateName[2]]: event.target.value
          }
        }
      })
    }
  }

  handleLostBaggage = event => {
    let { name, value } = event.target
    let newState = this.state.travelInfo
    newState = {
      ...this.state.travelInfo,
      lostBaggage: {
        ...this.state.travelInfo.lostBaggage,
        [name]: value
      }
    }
    this.setState({
      travelInfo: newState
    })
  }

  handleMedicalLimit = event => {
    let { name, value } = event.target
    let newState = this.state.travelInfo
    newState = {
      ...this.state.travelInfo,
      medicalLimit: {
        ...this.state.travelInfo.medicalLimit,
        [name]: value
      }
    }
    this.setState({
      travelInfo: newState
    })
  }

  handleSumAssured = event => {
    let { name, value } = event.target
    let newState = this.state.travelInfo
    newState = {
      ...this.state.travelInfo,
      sumAssured: {
        ...this.state.travelInfo.sumAssured,
        [name]: value
      }
    }
    this.setState({
      travelInfo: newState
    })
  }

  setNumberOfTravelers = data => {
    let result = data.travelInfo
    if (data.travelInfo.isFamily === 'true') {
      result = {
        ...data.travelInfo,
        numberOfTravellers: {
          lower: 3,
          upper: 5
        }
      }
    } else if (data.travelInfo.isFamily === 'false') {
      result = {
        ...data.travelInfo,
        numberOfTravellers: {
          lower: 1,
          upper: 10000
        }
      }
    }

    return result
  }

  handleAddPremium = () => {
    this.setState({
      travelPremiums: this.state.travelPremiums.concat([
        {
          ageRange: {
            upper: '',
            lower: ''
          },
          premium: ''
        }
      ])
    })
  }

  handleRemovePremium = id => {
    this.setState({
      travelPremiums: this.state.travelPremiums.filter(
        (premium, premiumId) => id !== premiumId
      )
    })
  }

  handleUpdatePremiumAmount = id => event => {
    const newPremiums = this.state.travelPremiums.map((premium, premiumId) => {
      if (id !== premiumId) return premium
      return {
        ...premium,
        premium: event.target.value
      }
    })
    this.setState({ travelPremiums: newPremiums })
  }

  handleUpdatePremiumAge = (stateName, id) => event => {
    const newPremiums = this.state.travelPremiums.map((premium, premiumId) => {
      if (id !== premiumId) return premium
      return {
        ...premium,
        ageRange: {
          ...premium.ageRange,
          [stateName]: event.target.value
        }
      }
    })
    this.setState({ travelPremiums: newPremiums })
  }

  handleAddCoverBreakdownItem = () => {
    this.setState({
      travelInfo: {
        ...this.state.travelInfo,
        travelCoverBreakdownSet: this.state.travelInfo.travelCoverBreakdownSet.concat(
          [
            {
              name: '',
              value: ''
            }
          ]
        )
      }
    })
  }

  handleRemoveCoverBreakdownItem = id => {
    this.setState({
      travelInfo: {
        ...this.state.travelInfo,
        travelCoverBreakdownSet: this.state.travelInfo.travelCoverBreakdownSet.filter(
          (coverBreakdownItem, itemId) => itemId !== id
        )
      }
    })
  }

  handleUpdateCoverBreakdownItem = id => event => {
    let { name, value } = event.target
    let newState = this.state.travelInfo
    newState = {
      ...this.state.travelInfo,
      travelCoverBreakdownSet: [
        ...this.state.travelInfo.travelCoverBreakdownSet.map((item, itemId) => {
          if (itemId !== id) return item
          return {
            ...item,
            [name]: value
          }
        })
      ]
    }
    // this.setState({ exceptions: breakdownItems });
    this.setState({ travelInfo: newState })
  }

  enableEditing = event => {
    event.preventDefault()
    this.setState({ isDisabled: false })
  }

  handleDeleteProduct = event => {
    event.preventDefault()
    let data = this.state
    let URL = `${process.env.REACT_APP_API_BASE}/api/v1/products/${data.id}/`

    axios
      .delete(URL)
      .then(res => {
        this.props.handleOk(true)
      })
      .catch(err => {
        console.log(err)
      })
  }

  onSubmit = event => {
    event.preventDefault()
    let currState = this.state
    let travelInfo = this.setNumberOfTravelers(currState)
    let data = {
      ...currState,
      travelInfo
    }

    // delete data.companies

    axios
      .post(travelProductsUrl, data, { headers: getHeaders() })
      .then(res => {
        this.props.handleOk(true)
      })
      .catch(err => {
        console.log(err)
      })
  }

  getCompanies() {
    axios
      .get(companiesUrl, { headers: getHeaders() })
      .then(res => {
        this.setState({
          companies: res.data.results.map(res => {
            return { ...res, value: res.id, label: res.name }
          })
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  componentDidMount() {
    this.getCompanies()
  }

  render() {
    let { isDisabled, isDelete } = this.props
    isDisabled = !!isDisabled
    isDisabled = isDisabled && this.state.isDisabled

    if (isDelete) {
      isDisabled = true
    }

    return (
      <form onSubmit={this.onSubmit}>
        <div className="row">
          <div className="col-md-6 mb-4">
            <label className="mb-1">Policy Name</label>
            <input
              type="text"
              value={this.state.name}
              className="form-control"
              onChange={this.handleInput(['name'])}
              required
              disabled={isDisabled}
            />
          </div>

          <div className="col-md-6">
            <label className="mb-1">Company</label>
            <select
              className="custom-select"
              onChange={this.handleInput(['company_id'])}
              value={this.state.company_id}
              required
              disabled={isDisabled}
            >
              <option defaultValue>Select</option>
              {this.state.companies.map(choice => (
                <option key={choice} value={choice.value}>
                  {choice.label}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-6">
            <label className="mb-1">Premium Currency</label>
            <select
              className="custom-select"
              onChange={this.handleInput(['currency'])}
              value={this.state.currency}
              required
              disabled={isDisabled}
            >
              <option defaultValue>Select</option>
              {Object.keys(yc.CURRENCY_CHOICES).map(choice => (
                <option key={choice} value={choice}>
                  {yc.CURRENCY_CHOICES[choice]}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-6">
            <label className="mb-1">Description</label>
            <div className="form-group">
              <textarea
                value={this.state.description}
                className="form-control"
                rows="8"
                onChange={this.handleInput(['description'])}
                required
                disabled={isDisabled}
              />
            </div>
          </div>

          <div className="col-md-6">
            <label className="mb-1">Region</label>
            <select
              className="custom-select"
              onChange={this.handleInput(['travelInfo', 'region'])}
              value={this.state.travelInfo.region}
              required
              disabled={isDisabled}
            >
              <option defaultValue>Select</option>
              {Object.keys(pc.TRAVEL_REGION_CHOICES).map(choice => (
                <option key={choice} value={choice}>
                  {pc.TRAVEL_REGION_CHOICES[choice]}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-6">
            <label className="mb-1">Sum Insured</label>
            <div className="row mb-3">
              <div className="col-md-4">
                <select
                  className="custom-select"
                  onChange={this.handleSumAssured}
                  value={this.state.travelInfo.sumAssured.currency}
                  name="currency"
                  required
                  disabled={isDisabled}
                >
                  <option defaultValue>Currency</option>
                  {Object.keys(yc.CURRENCY_CHOICES).map(choice => (
                    <option key={choice} value={choice}>
                      {yc.CURRENCY_CHOICES[choice]}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-8">
                <input
                  type="text"
                  value={this.state.travelInfo.sumAssured.amount}
                  name="amount"
                  className="form-control"
                  aria-describedby="basic-addon1"
                  onChange={this.handleSumAssured}
                  required
                  disabled={isDisabled}
                />
              </div>
            </div>

            <label className="mb-1">Medical Limit</label>
            <div className="row mb-3">
              <div className="col-md-4">
                <select
                  className="custom-select"
                  onChange={this.handleMedicalLimit}
                  name="currency"
                  value={this.state.travelInfo.medicalLimit.currency}
                  required
                  disabled={isDisabled}
                >
                  <option defaultValue>Currency</option>
                  {Object.keys(yc.CURRENCY_CHOICES).map(choice => (
                    <option key={choice} value={choice}>
                      {yc.CURRENCY_CHOICES[choice]}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-8">
                <input
                  type="text"
                  value={this.state.travelInfo.medicalLimit.amount}
                  name="amount"
                  className="form-control"
                  aria-describedby="basic-addon1"
                  onChange={this.handleMedicalLimit}
                  required
                  disabled={isDisabled}
                />
              </div>
            </div>

            <label className="mb-1">Lost Baggage</label>
            <div className="row mb-3">
              <div className="col-md-4">
                <select
                  className="custom-select"
                  onChange={this.handleLostBaggage}
                  value={this.state.travelInfo.lostBaggage.currency}
                  name="currency"
                  required
                  disabled={isDisabled}
                >
                  <option defaultValue>Currency</option>
                  {Object.keys(yc.CURRENCY_CHOICES).map(choice => (
                    <option key={choice} value={choice}>
                      {yc.CURRENCY_CHOICES[choice]}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-8">
                <input
                  type="text"
                  value={this.state.travelInfo.lostBaggage.amount}
                  name="amount"
                  className="form-control"
                  aria-describedby="basic-addon1"
                  onChange={this.handleLostBaggage}
                  required
                  disabled={isDisabled}
                />
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-4">
            <label className="mb-1">Exceptions</label>
            <select
              className="custom-select"
              onChange={this.handleInput(['travelInfo', 'exceptions'])}
              value={this.state.travelInfo.exceptions}
              required
              disabled={isDisabled}
            >
              <option defaultValue>Select</option>
              {Object.keys(pc.TRAVEL_EXCEPTION_CHOICES).map(choice => (
                <option key={choice} value={choice}>
                  {pc.TRAVEL_EXCEPTION_CHOICES[choice]}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-6">
            <label className="mb-1">Trip Type</label>
            <select
              className="custom-select"
              onChange={this.handleInput(['travelInfo', 'isSingleTrip'])}
              value={this.state.travelInfo.isSingleTrip}
              required
              disabled={isDisabled}
            >
              <option defaultValue>Select</option>
              <option value={true}>Single Trip</option>
              <option value={false}>Multiple Trip</option>
            </select>
          </div>

          <div className="col-md-6 mb-4">
            <label className="mb-1">Cover Type</label>
            <select
              className="custom-select"
              onChange={this.handleInput(['travelInfo', 'isFamily'])}
              value={this.state.travelInfo.isFamily}
              required
              disabled={isDisabled}
            >
              <option defaultValue>Select</option>
              <option value={false}>Individual</option>
              <option value={true}>Family</option>
            </select>
          </div>

          <div className="col-md-6 mb-4">
            <label className="mb-1">Maximum duration(days)</label>
            <input
              type="text"
              value={this.state.travelInfo.maxDuration}
              className="form-control"
              onChange={this.handleInput(['travelInfo', 'maxDuration'])}
              required
              disabled={isDisabled}
            />
          </div>

          <div className="col-md-12 mb-1">
            <label>Premiums</label>
          </div>
          {this.state.travelPremiums.map((premium, id) => (
            <div className="col-md-12 mt-1 mb-1" key={id}>
              <div className="row">
                <div className="col-md-6">
                  <label className="mb-1">Age range</label> <br />
                  <span className="mt-1">From</span>
                  <input
                    type="text"
                    value={premium.ageRange.lower}
                    className="form-control"
                    aria-describedby="basic-addon1"
                    onChange={this.handleUpdatePremiumAge('lower', id)}
                    disabled={isDisabled}
                  />
                  To
                  <input
                    type="text"
                    value={premium.ageRange.upper}
                    className="form-control"
                    aria-describedby="basic-addon1"
                    onChange={this.handleUpdatePremiumAge('upper', id)}
                    disabled={isDisabled}
                  />
                </div>

                <div className="col-md-6">
                  <label className="mb-1">Amount</label>
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        value={premium.premium}
                        type="text"
                        className="form-control"
                        aria-describedby="basic-addon1"
                        onChange={this.handleUpdatePremiumAmount(id)}
                        disabled={isDisabled}
                      />
                    </div>
                    {!isDisabled ? (
                      <div className="col-md-2 align-middle">
                        <span
                          className="align-middle"
                          style={{
                            color: 'red',
                            fontSize: '16px',
                            cursor: 'pointer'
                          }}
                          onClick={() => this.handleRemovePremium(id)}
                        >
                          &times;
                        </span>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          ))}
          {!isDisabled ? (
            <div className="col-lg-12 mt-2 mb-4" style={{ color: '#20d670' }}>
              <span>+</span>
              <span
                onClick={this.handleAddPremium}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                {' '}
                Add premium
              </span>
            </div>
          ) : null}

          <div className="col-md-12 mb-1">
            <label>Cover Breakdown Details</label>
          </div>
          {this.state.travelInfo.travelCoverBreakdownSet.map((item, id) => (
            <div className="col-lg-12 mt-1 mb-1" key={id}>
              <div className="row">
                <div className="col-md-5">
                  <label className="mb-1">Name</label>
                  <div className="row">
                    <div className="col-md-12">
                      <input
                        type="text"
                        value={item.name}
                        name="name"
                        className="form-control"
                        onChange={this.handleUpdateCoverBreakdownItem(id)}
                        disabled={isDisabled}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-7">
                  <label className="mb-1">Value</label>
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="text"
                        value={item.value}
                        name="value"
                        className="form-control"
                        onChange={this.handleUpdateCoverBreakdownItem(id)}
                        disabled={isDisabled}
                      />
                    </div>
                    <div className="col-md-2">
                      {!isDisabled ? (
                        <span
                          className="align-middle"
                          style={{
                            color: 'red',
                            fontSize: '16px',
                            cursor: 'pointer'
                          }}
                          onClick={() =>
                            this.handleRemoveCoverBreakdownItem(id)
                          }
                        >
                          &times;
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {!isDisabled ? (
            <div className="col-lg-12 mt-2 mb-4" style={{ color: '#20d670' }}>
              <span>+</span>
              <span
                onClick={this.handleAddCoverBreakdownItem}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
              >
                {' '}
                Add cover breakdown Item
              </span>
            </div>
          ) : null}

          {!isDisabled ? (
            <div className="col-md-12">
              <div className="row">
                <div className="col">
                  <hr />
                </div>
              </div>
              <div className="row">
                <div className="col text-right">
                  <button type="submit" className="btn btn-success btn-sm mr-3">
                    Save
                  </button>
                  <button
                    className="btn btn-outline-secondary btn-sm"
                    onClick={this.props.handleCancel}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div>
              {isDelete ? null : (
                <div className="col-md-12">
                  <div className="row">
                    <div className="col">
                      <hr />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-right">
                      <button
                        className="btn btn-success btn-sm mr-3"
                        onClick={this.enableEditing}
                      >
                        Edit
                      </button>
                      <button
                        className="btn btn-outline-secondary btn-sm"
                        onClick={this.props.handleCancel}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </form>
    )
  }
}

export default Create
