import React from 'react'
// import Page from "components/LayoutComponents/Page";
import Helmet from 'react-helmet'
import Client from './Client'
import * as c from 'app-constants/accounts'

class Clients extends React.Component {
  static defaultProps = {
    pathName: 'Clients',
    roles: [c.MANAGEMENT, c.FINANCE, c.SALES]
  }

  render() {
    return (
      <div>
        <Helmet title="All Clients" />
        <Client />
      </div>
    )
  }
}

export default Clients
