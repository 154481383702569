import React from 'react'
// import Page from "components/LayoutComponents/Page";
import Helmet from 'react-helmet'
import Promo from './Home'
import * as c from 'app-constants/accounts'

class Promos extends React.Component {
  static defaultProps = {
    pathName: 'Promos',
    roles: [c.MANAGEMENT, c.FINANCE, c.SALES]
  }

  render() {
    return (
      <div>
        <Helmet title="Promo Codes" />
        <Promo />
      </div>
    )
  }
}

export default Promos
