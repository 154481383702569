import React from 'react'
import { Modal, Breadcrumb } from 'antd'
import axios from 'axios/index'
import * as pc from 'app-constants/products'
import * as oc from 'app-constants/policies'
import 'c3/c3.css'
import './style.scss'
import Property from './Property'
import { propertyUrl, getHeaders } from '../../../../config'
import { withRouter } from 'react-router-dom'
import FilterTable from 'components/Forms/searchFilter'
import PageLoader from 'components/Layouts/PageLoader'

class Policies extends React.Component {
  state = {
    propertyTableData: [],

    policiesSold: {
      amounts: [
        {
          currency: 'GHS',
          amount: 0
        }
      ],
      number: 0,
      data: [],
      dataByProduct: {}
    },
    productLimit: '7',
    chartType: pc.TRAVEL,

    showPropertyModal: false,
    selectedProperty: null,
    loading: false
  }

  // update tables
  updateTableResults() {
    // const { dispatch } = this.props
    // dispatch(setLoading(true));
    this.setState({ loading: true })
    axios
      .get(propertyUrl, { headers: getHeaders() })
      .then(results => {
        this.setState({ loading: false })
        // property request
        let property_policies = []
        results.data.results.forEach(policy => {
          policy.houses.forEach(house => {
            property_policies.push({
              ...policy,
              ...house,
              customer: `${policy.user.firstName} ${policy.user.lastName}`,
              product: pc.PRODUCT_TYPE_CHOICES[policy.property_type],
              company: policy.company.name,
              type: pc.PRODUCT_TYPE_CHOICES[policy.property_type],
              typeName: pc.PRODUCT_TYPE_CHOICES['P'],
              premiumAmount: `${policy.premium.currency} ${policy.premium.amount}`,
              houseAddress: `${house.address}`,
              houseLocation: `${house.location}`,
              houseValue: `${house.value}`,
              houseResidents: `${house.residents.length}`,
              statusName: oc.POLICY_STATUS_CHOICES[policy.status],
              key: house.id,
              countries: ['NA'],
              policyId: policy.id
            })
          })
        })
        // end property request

        this.setState({
          propertyTableData: property_policies
        })
      })
      .catch(error => {
        this.setState({ loading: false })
        console.log(error)
      })
      .then(() => {
        // dispatch(setLoading(false));
      })
  }
  // end update tables

  componentDidMount() {
    this.updateTableResults()
  }

  handleShowPropertyModal = () => {
    console.log('Show modal')
    this.setState({
      showPropertyModal: true
    })
  }

  handlePropertyOk = e => {
    console.log(e)
    this.setState({
      showPropertyModal: false
    })
  }

  handlePropertyCancel = e => {
    console.log(e)
    this.setState(
      {
        showPropertyModal: false
      },
      () => this.updateTableResults()
    )
  }

  downloadSales = event => {
    event.preventDefault()

    let csvContent = 'data:text/csv;charset=utf-8,'
    let headers =
      'Policy number, Customer, Policy, Company, Type, Premium, Status'
    csvContent += headers + '\r\n'

    this.state.travelTableData.forEach(policy => {
      let row = `${policy.number}, ${policy.customer}, ${policy.product}, ${
        policy.company
      }, ${policy.typeName}, ${policy.premiumAmount}, ${
        oc.POLICY_STATUS_CHOICES[policy.status]
      }`
      csvContent += row + '\r\n'
    })

    let encodedUri = encodeURI(csvContent)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'sales.csv')
    document.body.appendChild(link) // Required for FF

    link.click()
  }

  printPage = event => {
    event.preventDefault()
    window.print()
  }

  handleInputChange = stateName => event => {
    this.setState({ [stateName]: event.target.value })
  }

  render() {
    if (this.props.isLoading) return null

    let propertyDetailRow = record => {
      this.setState({
        selectedProperty: record,
        showPropertyModal: true
      })
    }

    let { propertyTableData } = this.state

    const PROPERTY_POLICY_TABLE_COLUMNS = [
      {
        title: 'Policy Number',
        dataIndex: 'number',
        key: 'number',
        width: 100
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        width: 120
      },
      {
        title: 'Customer',
        dataIndex: 'customer',
        key: 'customer',
        width: 120
      },
      {
        title: 'House Address',
        dataIndex: 'houseAddress',
        key: 'houseAddress',
        width: 120
      },
      {
        title: 'House Location',
        dataIndex: 'houseLocation',
        key: 'houseLocation',
        width: 120
      },
      {
        title: 'House Value',
        dataIndex: 'houseValue',
        key: 'houseValue',
        width: 120
      },
      {
        title: 'Residents',
        dataIndex: 'houseResidents',
        key: 'houseResidents',
        width: 120
      },
      {
        title: 'Premium',
        dataIndex: 'premiumAmount',
        key: 'premium',
        width: 120
        // sorter: (a, b) => a.premium.amount - b.premium.amount
      },
      {
        title: 'Status',
        dataIndex: 'statusName',
        key: 'status',
        fixed: 'right',
        width: 80
        // filters: [
        //   {
        //     text: 'Unpaid',
        //     value: oc.UNPAID
        //   },
        //   {
        //     text: 'Active',
        //     value: oc.ACTIVE
        //   },
        //   {
        //     text: 'Inactive',
        //     value: oc.INACTIVE
        //   },
        //   {
        //     text: 'Cancelled',
        //     value: oc.CANCELLED
        //   }
        // ],
        // onFilter: (value, record) => record.status === value
      }
    ]

    return (
      <PageLoader
        className="row mb-5rem pl-4rem pr-4rem"
        loading={this.state.loading}
      >
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            {/* <HomeOutlined /> */}
            <span>Home</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Home Policies</Breadcrumb.Item>
        </Breadcrumb>
        <br />
        <br />
        <Modal
          title="Property Details"
          centered
          visible={this.state.showPropertyModal}
          onOk={this.handlePropertyOk}
          onCancel={this.handlePropertyCancel}
          footer={null}
        >
          {this.state.showPropertyModal ? (
            <Property
              handleCancel={this.handlePropertyCancel}
              policy={this.state.selectedProperty}
            />
          ) : null}
        </Modal>

        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <div className="utils__title">
                <span>Property Policies</span>
              </div>
            </div>
            <div className="card-body">
              <div style={{ marginBottom: 16 }}>
                {/* <button
                  className="btn_normal btn_gradient btn_md"
                  onClick={() => this.props.history.push('/buy-home-insurance')}
                >
                  Buy New Policy
                </button> */}
                {/*
                <span style={{ marginLeft: 8 }}>
                  {hasSelected
                    ? `Selected ${selectedRowKeys.length} items`
                    : ""}
                </span> */}
              </div>
              <FilterTable
                columns={PROPERTY_POLICY_TABLE_COLUMNS}
                dataSource={propertyTableData}
                onRow={propertyDetailRow}
              />
            </div>
          </div>
        </div>
      </PageLoader>
    )
  }
}

export default withRouter(Policies)
