import React from 'react'
import { Space, Modal, Breadcrumb } from 'antd'
import axios from 'axios/index'
import 'c3/c3.css'
import './style.scss'
import Detail from './Detail'
import { promoUrl, getHeaders } from '../../../config'
import { withRouter } from 'react-router-dom'
import FilterTable from 'components/Forms/searchFilter'
import PageLoader from 'components/Layouts/PageLoader'
import Create from './Create'

class Promo extends React.Component {
  state = {
    tableData: [],
    showModal: false,
    showCreateModal: false,
    selected: null,
    loading: false
  }

  // update tables
  updateTableResults() {
    // const { dispatch } = this.props
    this.setState({ loading: true })
    axios
      .get(promoUrl, { headers: getHeaders() })
      .then(res => {
        this.setState({ loading: false })
        // motor request
        let promos = res.data.results.map(dep => {
          return {
            ...dep,
            discount: `${dep.discountPercentage}%`,
            dateAdded: new Date(dep.createdAt).toDateString(),
            startAt: new Date(dep.startDate).toDateString(),
            endAt: new Date(dep.endDate).toDateString()
          }
        })
        // end motor request

        this.setState({
          tableData: promos
        })
        console.log('DATA SET')
      })
      .catch(error => {
        this.setState({ loading: false })
        console.log(error)
      })
    // .then(() => {
    //   // dispatch(setLoading(false));
    // })
  }
  // end update tables

  componentDidMount() {
    this.updateTableResults()
  }

  handleShowModal = () => {
    console.log('Show modal')
    this.setState({
      showModal: true
    })
  }

  handleOk = e => {
    console.log(e)
    this.setState({
      showModal: false
    })
  }

  handleCancel = e => {
    console.log(e)
    this.setState(
      {
        showModal: false
      },
      () => this.updateTableResults()
    )
  }

  handleShowCreateModal = () => {
    console.log('Show modal')
    this.setState({
      showCreateModal: true
    })
  }

  handleCreateOk = e => {
    console.log(e)
    this.setState({
      showCreateModal: false
    })
  }

  handleCreateCancel = e => {
    console.log(e)
    this.setState(
      {
        showCreateModal: false
      },
      () => this.updateTableResults()
    )
  }

  handleInputChange = stateName => event => {
    this.setState({ [stateName]: event.target.value })
  }

  render() {
    if (this.props.isLoading) return null

    let detailRow = record => {
      this.setState({ selected: record, showModal: true })
    }

    let { tableData } = this.state

    const columns = [
      {
        title: 'Date Added',
        dataIndex: 'dateAdded',
        key: 'dateAdded',
        width: 120
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        width: 120
      },
      {
        title: 'Code',
        dataIndex: 'promoCode',
        key: 'promoCode',
        width: 120
      },
      {
        title: 'Discount',
        dataIndex: 'discount',
        key: 'discount',
        width: 120
      },
      {
        title: 'Start Date',
        dataIndex: 'startAt',
        key: 'startAt',
        width: 150
        // fixed: 'left'
      },

      {
        title: 'End Date',
        dataIndex: 'startAt',
        key: 'startAt',
        width: 90
      },

      {
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 80,
        render: (text, record) => (
          <Space size="middle">
            <span style={{ color: 'blue', cursor: 'pointer' }}>View</span>
          </Space>
        )
      }
    ]

    return (
      <PageLoader className="row main-page" loading={this.state.loading}>
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            {/* <HomeOutlined /> */}
            <span>Home</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Promo Codes</Breadcrumb.Item>
        </Breadcrumb>
        <br />
        <br />
        <Modal
          title="Promo Details"
          centered
          visible={this.state.showModal}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
        >
          {this.state.showModal ? (
            <Detail
              handleCancel={this.handleCancel}
              promo={this.state.selected}
            />
          ) : null}
        </Modal>

        <Modal
          title="New Promo Code"
          centered
          visible={this.state.showCreateModal}
          onOk={this.handleCreateOk}
          onCancel={this.handleCreateCancel}
          footer={null}
        >
          {this.state.showCreateModal ? (
            <Create handleCancel={this.handleCreateCancel} />
          ) : null}
        </Modal>

        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <div className="utils__title">
                <span>Promo Codes</span>
              </div>
            </div>
            <div className="card-body">
              <div style={{ marginBottom: 16 }}>
                <button
                  className="btn_normal btn_gradient btn_md"
                  onClick={() => this.handleShowCreateModal()}
                >
                  New Promo Code
                </button>
              </div>

              <FilterTable
                columns={columns}
                dataSource={tableData}
                onRow={detailRow}
              />
            </div>
          </div>
        </div>
      </PageLoader>
    )
  }
}

export default withRouter(Promo)
