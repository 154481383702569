import React, { Component } from 'react'
// import axios from 'axios'
// import { Link } from 'react-router-dom'

// import * as p from 'app-constants/policies'
// import * as pc from 'app-constants/products'

class Detail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: this.props.email
    }
  }

  render() {
    let { email } = this.props

    return (
      <div>
        <form>
          <div className="row">
            <div className="col-md-6 mb-4">
              <label className="mb-1">Full Name</label>
              <input
                type="text"
                disabled
                value={email.name}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            <div className="col-md-6 mb-4">
              <label className="mb-1">Email Address</label>
              <input
                type="text"
                disabled
                value={email.email}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="mb-1">Phone Number</label>
              <input
                type="text"
                disabled
                value={email.phone}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            <div className="col-md-6 mb-4">
              <label className="mb-1">Date Added</label>
              <input
                type="text"
                disabled
                value={`${new Date(
                  email.createdOn
                ).toDateString()} | ${new Date(
                  email.createdOn
                ).toTimeString()}`}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="mb-1">Date Updated</label>
              <input
                type="text"
                disabled
                value={`${new Date(
                  email.lastUpdate
                ).toDateString()} | ${new Date(
                  email.lastUpdate
                ).toTimeString()}`}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            {/* <div className="col-md-12 mt-4">
              <div className="row">
                <div
                  className="col"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <button
                    className="btn btn-outline-secondary btn-sm"
                    onClick={this.props.handleCancel}
                  >
                    Close
                  </button>
                  {[p.PAID, p.ACTIVE].includes(policy.status) ? (
                    <Link
                      to={{
                        pathname: `/claims/motor/new/${policy.policyId}`
                      }}
                      className="navbar__link"
                    >
                      <button className="btn btn--width-lng btn--gradient-primary">
                        Make Claim
                      </button>
                    </Link>
                  ) : (
                    <Link
                      to={`/buy-motor-insurance/${policy.policyId}/buy`}
                      className="navbar__link"
                    >
                      <button className="btn btn--width-lng btn--gradient-primary">
                        Make Payment
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div> */}
          </div>
        </form>
      </div>
    )
  }
}

export default Detail
