import React, { Component } from 'react'
import { Redirect, withRouter, Link } from 'react-router-dom'
import axios from 'axios'
import { loginUrl, OTPVerificationUrl, OTPUrl, getHeaders } from '../../config'
import WithUser from 'hocs/WithUser'
import PropTypes from 'prop-types'
import { Input } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

class LoginForm extends Component {
  state = {
    email: '',
    password: '',
    otp: '',
    phoneNumber: '',
    otpSent: false,
    isSubmitting: false,
    redirectToReferrer: false,
    errorMessage: {},
    showModal: false
  }

  handleInputUpdate = (stateName, event) => {
    this.setState({ [stateName]: event.target.value })
  }

  handleSubmit = event => {
    event.preventDefault()
    this.setState({ isSubmitting: true })
    let data = this.state
    axios
      .post(loginUrl, data)
      .then(res => {
        if (res.data.user.isStaff) {
          this.props.setUser(res.data)
          this.setState({
            showModal: false,
            isSubmitting: false,
            redirectToReferrer: true
          })
        } else {
          this.setState({
            isSubmitting: false,
            errorMessage: {
              ...this.state.errorMessage,
              nonFieldErrors: ['Your account is not an admin account.']
            }
          })
        }
      })
      .catch(error => {
        if (error.response && error.response.status < 500) {
          this.setState({ errorMessage: error.response.data })
        } else {
          this.setState({
            errorMessage: {
              nonFieldErrors: ['Oops! Something happened. Please try again.']
            }
          })
        }

        this.setState({ isSubmitting: false })
      })
  }

  handleSendOTP = event => {
    event.preventDefault()
    this.setState({ isSubmitting: true })
    // let { phoneNumber } = this.state
    axios
      .post(OTPUrl, { headers: getHeaders() })
      .then(res => {
        this.setState({ otpSent: true, isSubmitting: false })
      })
      .catch(error => {
        if (error.response && error.response.status < 500) {
          this.setState({ errorMessage: error.response.data })
        } else {
          this.setState({
            errorMessage: {
              nonFieldErrors: ['Oops! Something happened. Please try again.']
            }
          })
        }

        this.setState({ isSubmitting: false, otpSent: false })
      })
  }

  handleVerifyOtp = event => {
    event.preventDefault()
    this.setState({ isSubmitting: true })
    let { otp, phoneNumber } = this.state

    axios
      .post(OTPVerificationUrl, { otp, phoneNumber }, { headers: getHeaders() })
      .then(res => {
        this.setState({ showModal: false, redirectToReferrer: true })
      })
      .catch(error => {
        if (error.response && error.response.status < 500) {
          this.setState({ errorMessage: error.response.data })
        } else {
          this.setState({
            errorMessage: {
              nonFieldErrors: ['Oops! Something happened. Please try again.']
            }
          })
        }

        this.setState({ isSubmitting: false })
      })
  }

  render() {
    const { from } = this.props.location.state || { from: { pathname: '/' } }
    const redirectPath = from.pathname
    const { redirectToReferrer } = this.state

    if (redirectToReferrer === true) {
      return <Redirect to={redirectPath} />
    }

    return (
      <React.Fragment>
        {/* <Modal
          title="Verify Phone Number"
          centered
          visible={this.state.showModal}
          onOk={() => this.setState({ showModal: false })}
          onCancel={() => this.setState({ showModal: false })}
          footer={null}
        >
          <form
            className=""
            onSubmit={
              !this.state.otpSent ? this.handleSendOTP : this.handleVerifyOtp
            }
          > */}
        {/* <di className="row">
              {this.state.otpSent ? (
                <>
                  <div className="col-md-12">
                    <span className="text--muted">
                      Please check your phone or email for verification code.
                    </span>
                    <label className="form__label mt-1rem mb-1rem">
                      Enter Verification Code
                    </label>
                    <input
                      className="form__input"
                      type="text"
                      placeholder="XXXX"
                      onChange={this.handleInputUpdate.bind(this, 'otp')}
                      value={otp}
                      required
                    />
                    {this.state.errorMessage.otp
                      ? this.state.errorMessage.email.map(err => (
                          <p style={{ color: 'red', marginTop: '1em' }}>
                            {err}
                          </p>
                        ))
                      : null}
                  </div>
                </>
              ) : (
                <div className="col-md-6">
                  <label className="mt-1rem mb-1rem">Phone Number</label>
                  <Input
                    className=""
                    placeholder="0245xxxxxx"
                    type="text"
                    onChange={this.handleInputUpdate.bind(this, 'phoneNumber')}
                    value={phoneNumber}
                    required
                  />
                  {this.state.errorMessage.phoneNumber
                    ? this.state.errorMessage.phoneNumber.map(err => (
                        <p style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                      ))
                    : null}
                </div>
              )}
            </di> */}

        {/* <button
              className="btn btn--gradient-primary btn--width-auto mt-2rem mb-2rem"
              type="submit"
            >
              {this.state.isSubmitting ? <LoadingOutlined /> : 'Submit'}
            </button>
          </form>
        </Modal> */}

        <form className="mt-2rem" onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="col-md-12 mb-1rem">
              <label htmlFor="email" style={{ fontWeight: '700' }}>
                Email Address
              </label>
              <Input
                className=""
                placeholder="john@doe.com"
                onChange={this.handleInputUpdate.bind(this, 'email')}
                autoFocus="autofocus"
                autoComplete="username"
                required
              />
              {this.state.errorMessage.email
                ? this.state.errorMessage.email.map(err => (
                    <p style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                  ))
                : null}
            </div>
            <div className="col-md-12 mt-2rem">
              <label htmlFor="password" style={{ fontWeight: '700' }}>
                Password
              </label>
              <Input.Password
                className=""
                placeholder="Enter password"
                type="password"
                onChange={this.handleInputUpdate.bind(this, 'password')}
                autoComplete="current-password"
                required
              />
              {this.state.errorMessage.password
                ? this.state.errorMessage.password.map(err => (
                    <p style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                  ))
                : null}
              <div
                style={{
                  color: 'rgb(0 66 65 / 69%)',
                  fontSize: '1.3rem',
                  marginTop: '0.5rem'
                }}
              >
                Can't remember your password?{' '}
                <Link to="/auth/password-reset">
                  <span className="navbar__link--active">Reset it Now</span>
                </Link>
              </div>
            </div>
          </div>
          <div>
            {this.state.errorMessage.nonFieldErrors
              ? this.state.errorMessage.nonFieldErrors.map(err => (
                  <p style={{ color: 'red', marginTop: '1em' }}>{err}</p>
                ))
              : null}
          </div>

          <div className="form__submit mt-3rem">
            <button
              className="btn btn--gradient-primary btn--width-lng mt-2rem mb-1rem"
              type="submit"
            >
              {this.state.isSubmitting ? <LoadingOutlined /> : 'Login'}
            </button>
          </div>
        </form>
      </React.Fragment>
    )
  }
}
LoginForm.propTypes = {
  setUser: PropTypes.func,
  location: PropTypes.shape({
    state: PropTypes.object
  })
}

export default withRouter(WithUser(LoginForm))
