import React, { Component } from 'react'
import { Table, Input, Button, Space } from 'antd'
import Highlighter from 'react-highlight-words'
import { SearchOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import { CSVLink } from 'react-csv'

class FilterTable extends Component {
  state = {
    searchText: '',
    searchedColumn: '',
    excelData: [],
    selectedRowKeys: [],
    selectedRowData: []
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false })
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex
              })
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100)
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  })

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    })
  }

  handleReset = clearFilters => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  render() {
    let filteredColumns = this.props.columns.map(i => {
      if (this.props.searchFields.includes(i.dataIndex)) {
        return {
          ...i,
          key: i.dataIndex,
          ...this.getColumnSearchProps(i.dataIndex)
        }
      }
      return {
        ...i,
        key: i.dataIndex
      }
    })

    let filteredData = this.props.dataSource.map(i => {
      return {
        ...i,
        key: i.id
      }
    })

    const onSelectChange = selectedRowKeys => {
      let updatedSelectedRowKeys = selectedRowKeys

      // eslint-disable-next-line
      updatedSelectedRowKeys.map(key => {
        // eslint-disable-next-line
        filteredData.map(data => {
          if (key === data.id) {
            let updatedExcelData = this.state.excelData
            updatedExcelData.push(data)
            this.setState({
              excelData: updatedExcelData,
              selectedRowData: updatedExcelData
            })
          }
        })
      })
      console.log('selectedRowKeys changed: ', updatedSelectedRowKeys)
      this.setState({ selectedRowKeys: updatedSelectedRowKeys })
    }

    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: onSelectChange
    }

    const hasSelected = this.state.selectedRowKeys.length > 0

    let onDetailRow = (record, rowIndex) => ({
      onClick: event => {
        // this.setState({ selectedMotor: record, showMotorModal: true })
        this.props.onRow(record)
      }
    })

    return (
      <div>
        {this.props.export ? (
          <div style={{ marginBottom: 16 }}>
            <Button
              type="primary"
              // onClick={download}
              disabled={!hasSelected}
            >
              <CSVLink
                data={this.state.selectedRowData}
                headers={this.props.dataHeaders}
                filename={`${this.props.fileTile}.csv`}
                target="_blank"
              >
                Export
              </CSVLink>
            </Button>
            <span style={{ marginLeft: 8 }}>
              {hasSelected
                ? `Selected ${this.state.selectedRowKeys.length} items`
                : ''}
            </span>
          </div>
        ) : null}
        <Table
          columns={filteredColumns}
          rowSelection={this.props.export ? rowSelection : null}
          dataSource={filteredData}
          pagination={this.props.pagination}
          boardered
          scroll={{ x: 300, y: 1500 }}
          onRow={onDetailRow}
        />
      </div>
    )
  }
}

FilterTable.propTypes = {
  dataSource: PropTypes.array,
  columns: PropTypes.array,
  searchFields: PropTypes.array,
  pagination: PropTypes.bool,
  onRow: PropTypes.func,
  export: PropTypes.bool,
  dataHeaders: PropTypes.array,
  fileTile: PropTypes.string
}

FilterTable.defaultProps = {
  searchFields: [],
  dataHeaders: [],
  export: false,
  fileTile: '',
  pagination: true
}

export default FilterTable
