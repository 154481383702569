import React, { Component } from 'react'
// import axios from 'axios'
// import { Link } from 'react-router-dom'

// import * as p from 'app-constants/policies'
// import * as pc from 'app-constants/products'

class Detail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      client: this.props.client
    }
  }

  render() {
    let { client } = this.props

    return (
      <div>
        <form>
          {/* <div className="text-center mb-2">
            <div className="alert alert-dark">
              <span>PAYMENT STATUS: </span>{' '}
              <span
                className={`badge badge-${
                  client.paymentColor === 'green' ? 'success' : 'danger'
                }`}
              >
                {client.payment}
              </span>
            </div>
          </div>
          <br /> */}
          <div className="row">
            <div className="col-md-6 mb-4">
              <label className="mb-1">First Name</label>
              <input
                type="text"
                disabled
                value={client.firstName}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="mb-1">Last Name</label>
              <input
                type="text"
                disabled
                value={client.lastName}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            <div className="col-md-6 mb-4">
              <label className="mb-1">Email Address</label>
              <input
                type="text"
                disabled
                value={client.email}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="mb-1">Phone Number</label>
              <input
                type="text"
                disabled
                value={client.phoneNumber}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            <div className="col-md-6 mb-4">
              <label className="mb-1">Occupation</label>
              <input
                type="text"
                disabled
                value={client.occupation}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="mb-1">Date of Birth</label>
              <input
                type="text"
                disabled
                value={new Date(client.dateOfBirth).toDateString()}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            <div className="col-md-6 mb-4">
              <label className="mb-1">Passport Number</label>
              <input
                type="text"
                disabled
                value={client.passportNumber}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="mb-1">Agent Name</label>
              <input
                type="text"
                disabled
                value={
                  client.agent
                    ? `${client.agent.firstName} ${client.agent.lastName}`
                    : 'N/A'
                }
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            <div className="col-md-6 mb-4">
              <label className="mb-1">Date Added</label>
              <input
                type="text"
                disabled
                value={`${new Date(
                  client.createdOn
                ).toDateString()} | ${new Date(
                  client.createdOn
                ).toTimeString()}`}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="mb-1">Date Updated</label>
              <input
                type="text"
                disabled
                value={`${new Date(
                  client.lastUpdate
                ).toDateString()} | ${new Date(
                  client.lastUpdate
                ).toTimeString()}`}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            {/* <div className="col-md-12 mt-4">
              <div className="row">
                <div
                  className="col"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <button
                    className="btn btn-outline-secondary btn-sm"
                    onClick={this.props.handleCancel}
                  >
                    Close
                  </button>
                  {[p.PAID, p.ACTIVE].includes(policy.status) ? (
                    <Link
                      to={{
                        pathname: `/claims/motor/new/${policy.policyId}`
                      }}
                      className="navbar__link"
                    >
                      <button className="btn btn--width-lng btn--gradient-primary">
                        Make Claim
                      </button>
                    </Link>
                  ) : (
                    <Link
                      to={`/buy-motor-insurance/${policy.policyId}/buy`}
                      className="navbar__link"
                    >
                      <button className="btn btn--width-lng btn--gradient-primary">
                        Make Payment
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div> */}
          </div>
        </form>
      </div>
    )
  }
}

export default Detail
