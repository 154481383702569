import React from 'react'

const Detail = props => {
  let { user } = props

  return (
    <div>
      <form>
        <div className="text-center mb-2">
          <div className="alert alert-dark">
            <span>STATUS: </span>{' '}
            <span className={`badge badge-success`}>{user.status}</span>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-6 mb-4">
            <label className="mb-1">First Name</label>
            <input
              type="text"
              disabled
              value={user.firstName}
              className="form-control"
              aria-describedby="basic-addon1"
            />
          </div>
          <div className="col-md-6 mb-4">
            <label className="mb-1">Last Name</label>
            <input
              type="text"
              disabled
              value={user.lastName}
              className="form-control"
              aria-describedby="basic-addon1"
            />
          </div>

          <div className="col-md-6 mb-4">
            <label className="mb-1">Email Address</label>
            <input
              type="text"
              disabled
              value={user.email}
              className="form-control"
              aria-describedby="basic-addon1"
            />
          </div>
          <div className="col-md-6 mb-4">
            <label className="mb-1">Admin Status</label>
            <input
              type="text"
              disabled
              value={user.isStaff}
              className="form-control"
              aria-describedby="basic-addon1"
            />
          </div>

          <div className="col-md-6 mb-4">
            <label className="mb-1">Affiliate Status</label>
            <input
              type="text"
              disabled
              value={user.isAffiliate}
              className="form-control"
              aria-describedby="basic-addon1"
            />
          </div>
          <div className="col-md-6 mb-4">
            <label className="mb-1">Affiliate Verified</label>
            <input
              type="text"
              disabled
              value={user.affiliateVerified}
              className="form-control"
              aria-describedby="basic-addon1"
            />
          </div>

          <div className="col-md-6 mb-4">
            <label className="mb-1">Date Joined</label>
            <input
              type="text"
              disabled
              value={user.dateJoined}
              className="form-control"
              aria-describedby="basic-addon1"
            />
          </div>

          <div className="col-md-6 mb-4">
            <label className="mb-1">Last Logged In</label>
            <input
              type="text"
              disabled
              value={user.lastLogin}
              className="form-control"
              aria-describedby="basic-addon1"
            />
          </div>

          {/* <div className="col-md-12 mt-4">
              <div className="row">
                <div
                  className="col"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <button
                    className="btn btn-outline-secondary btn-sm"
                    onClick={this.props.handleCancel}
                  >
                    Close
                  </button>
                  {[p.PAID, p.ACTIVE].includes(user.status) ? (
                    <Link
                      to={{
                        pathname: `/claims/motor/new/${user.userId}`
                      }}
                      className="navbar__link"
                    >
                      <button className="btn btn--width-lng btn--gradient-primary">
                        Make Claim
                      </button>
                    </Link>
                  ) : (
                    <Link
                      to={`/buy-motor-insurance/${user.userId}/buy`}
                      className="navbar__link"
                    >
                      <button className="btn btn--width-lng btn--gradient-primary">
                        Make Payment
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div> */}
        </div>
      </form>
    </div>
  )
}

export default Detail
