import React from 'react'
// import Page from "components/LayoutComponents/Page";
import Helmet from 'react-helmet'
import Email from './email'
import * as c from 'app-constants/accounts'

class Emails extends React.Component {
  static defaultProps = {
    pathName: 'Emails',
    roles: [c.MANAGEMENT, c.FINANCE, c.SALES]
  }

  render() {
    return (
      <div>
        <Helmet title="Email Subscriptions" />
        <Email />
      </div>
    )
  }
}

export default Emails
