import React, { useRef, useEffect } from 'react'
import LoadingBar from 'react-top-loading-bar'
import PropTypes from 'prop-types'

const PageLoader = ({ children, loading, className }) => {
  // eslint-disable-next-line
  const loader = useRef(null)
  const mounted = useRef()

  useEffect(() => {
    if (mounted.current) {
      console.log('UPDATED')
      loading ? loader.current.continuousStart() : loader.current.complete()
    } else {
      console.log('MOUNTED')
      mounted.current = true
    }
  }, [loading])

  return (
    <div className={className}>
      <LoadingBar color="#58d1d0" ref={loader} height={3} />
      {children}
    </div>
  )
}

PageLoader.propTypes = {
  loading: PropTypes.bool.isRequired,
  className: PropTypes.string
}
export default PageLoader
