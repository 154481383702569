import React from 'react'

const Detail = props => {
  let { policy } = props

  return (
    <div>
      <form>
        <div className="text-center mb-2">
          <div className="alert alert-dark">
            <span>STATUS: </span>{' '}
            <span className={`badge badge-success`}>{policy.status}</span>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-6 mb-4">
            <label className="mb-1">First Name</label>
            <input
              type="text"
              disabled
              value={policy.firstName}
              className="form-control"
              aria-describedby="basic-addon1"
            />
          </div>
          <div className="col-md-6 mb-4">
            <label className="mb-1">Last Name</label>
            <input
              type="text"
              disabled
              value={policy.lastName}
              className="form-control"
              aria-describedby="basic-addon1"
            />
          </div>

          <div className="col-md-6 mb-4">
            <label className="mb-1">Email Address</label>
            <input
              type="text"
              disabled
              value={policy.email}
              className="form-control"
              aria-describedby="basic-addon1"
            />
          </div>
          <div className="col-md-6 mb-4">
            <label className="mb-1">Policy</label>
            <input
              type="text"
              disabled
              value={policy.policyType}
              className="form-control"
              aria-describedby="basic-addon1"
            />
          </div>

          <div className="col-md-6 mb-4">
            <label className="mb-1">Amount</label>
            <input
              type="text"
              disabled
              value={policy.amount}
              className="form-control"
              aria-describedby="basic-addon1"
            />
          </div>
          <div className="col-md-6 mb-4">
            <label className="mb-1">Duration</label>
            <input
              type="text"
              disabled
              value={policy.duration}
              className="form-control"
              aria-describedby="basic-addon1"
            />
          </div>

          <div className="col-md-6 mb-4">
            <label className="mb-1">Company</label>
            <input
              type="text"
              disabled
              value={policy.companyName}
              className="form-control"
              aria-describedby="basic-addon1"
            />
          </div>
          <div className="col-md-6 mb-4">
            <label className="mb-1">Payment Channel</label>
            <input
              type="text"
              disabled
              value={policy.paymentChannel}
              className="form-control"
              aria-describedby="basic-addon1"
            />
          </div>

          <div className="col-md-6 mb-4">
            <label className="mb-1">Payment Reference</label>
            <input
              type="text"
              disabled
              value={policy.reference}
              className="form-control"
              aria-describedby="basic-addon1"
            />
          </div>
          <div className="col-md-6 mb-4">
            <label className="mb-1">Timestamp</label>
            <input
              type="text"
              disabled
              value={policy.timestamp}
              className="form-control"
              aria-describedby="basic-addon1"
            />
          </div>

          {/* <div className="col-md-12 mt-4">
              <div className="row">
                <div
                  className="col"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <button
                    className="btn btn-outline-secondary btn-sm"
                    onClick={this.props.handleCancel}
                  >
                    Close
                  </button>
                  {[p.PAID, p.ACTIVE].includes(policy.status) ? (
                    <Link
                      to={{
                        pathname: `/claims/motor/new/${policy.policyId}`
                      }}
                      className="navbar__link"
                    >
                      <button className="btn btn--width-lng btn--gradient-primary">
                        Make Claim
                      </button>
                    </Link>
                  ) : (
                    <Link
                      to={`/buy-motor-insurance/${policy.policyId}/buy`}
                      className="navbar__link"
                    >
                      <button className="btn btn--width-lng btn--gradient-primary">
                        Make Payment
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div> */}
        </div>
      </form>
    </div>
  )
}

export default Detail
