import React, { Component } from 'react'
// import axios from 'axios'
// import { Link } from 'react-router-dom'

// import * as p from 'app-constants/policies'
// import * as pc from 'app-constants/products'

class Detail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      promo: this.props.promo
    }
  }

  render() {
    let { promo } = this.props

    let policies = []
    if (promo.policyTypes && promo.policyTypes.length) {
      policies = promo.policyTypes.map(val => {
        return val.title
      })
    }

    return (
      <div>
        <form>
          <div className="row">
            <div className="col-md-6 mb-4">
              <label className="mb-1">Title</label>
              <input
                type="text"
                disabled
                value={promo.title}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="mb-1">Promo Code</label>
              <input
                type="text"
                disabled
                value={promo.promoCode}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            <div className="col-md-6 mb-4">
              <label className="mb-1">Discount Percentage</label>
              <input
                type="text"
                disabled
                value={`${promo.discountPercentage}%`}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="mb-1">Starting At</label>
              <input
                type="text"
                disabled
                value={promo.startAt}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            <div className="col-md-6 mb-4">
              <label className="mb-1">Ending At</label>
              <input
                type="text"
                disabled
                value={promo.endAt}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            <div className="col-md-6 mb-4">
              <label className="mb-1">Policies Applied</label>
              <input
                type="text"
                disabled
                value={policies.join(', ')}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            <div className="col-md-6 mb-4">
              <label className="mb-1">Date Added</label>
              <input
                type="text"
                disabled
                value={`${new Date(
                  promo.createdAt
                ).toDateString()} | ${new Date(
                  promo.createdAt
                ).toTimeString()}`}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>
            <div className="col-md-6 mb-4">
              <label className="mb-1">Date Updated</label>
              <input
                type="text"
                disabled
                value={`${new Date(
                  promo.updatedAt
                ).toDateString()} | ${new Date(
                  promo.updatedAt
                ).toTimeString()}`}
                className="form-control"
                aria-describedby="basic-addon1"
              />
            </div>

            {/* <div className="col-md-12 mt-4">
              <div className="row">
                <div
                  className="col"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <button
                    className="btn btn-outline-secondary btn-sm"
                    onClick={this.props.handleCancel}
                  >
                    Close
                  </button>
                  {[p.PAID, p.ACTIVE].includes(policy.status) ? (
                    <Link
                      to={{
                        pathname: `/claims/motor/new/${policy.policyId}`
                      }}
                      className="navbar__link"
                    >
                      <button className="btn btn--width-lng btn--gradient-primary">
                        Make Claim
                      </button>
                    </Link>
                  ) : (
                    <Link
                      to={`/buy-motor-insurance/${policy.policyId}/buy`}
                      className="navbar__link"
                    >
                      <button className="btn btn--width-lng btn--gradient-primary">
                        Make Payment
                      </button>
                    </Link>
                  )}
                </div>
              </div>
            </div> */}
          </div>
        </form>
      </div>
    )
  }
}

export default Detail
