import React, { useState } from 'react'
import { withRouter } from 'react-router'
import WithUser from 'hocs/WithUser'
import DashboardNavbar from './DashboardNavbar'
import DashboardSidebar from './DashboardSidebar'
// import Footer from 'components/Footer'
import { Drawer } from 'antd'

const DashboardLayout = ({ children }) => {
  const [showDrawer, setShowDrawer] = useState(false)

  return (
    <div className="dashboard">
      <div className="dashboard_nav">
        <div className="dashboard_nav_side">
          <DashboardSidebar />
        </div>

        <div className="dashboard_nav_top">
          <DashboardNavbar handleOpen={() => setShowDrawer(true)} />
        </div>
      </div>

      <div className="dashboard_page">
        {children}
        {/* <Footer className="mt-5rem" /> */}
      </div>
      <Drawer
        title="Menu"
        placement={'left'}
        onClose={() => setShowDrawer(false)}
        visible={showDrawer}
        className="navbar_drawer"
      >
        <DashboardSidebar />
      </Drawer>
    </div>
  )
}

export default withRouter(WithUser(DashboardLayout))
