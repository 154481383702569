import React from 'react'
import { Switch, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import PrivateRoute from 'hocs/PrivateRoute'
import TravelRoutes from '../travel-insurance'
import PropertyRoutes from '../property/PropertyRoutes'
import MotorRoutes from '../motor-insurance'
import HealthRoutes from '../health-insurance'
import LifeRoutes from '../life-insurance'
import PaymentRoutes from '../../components/Payments/PaymentRoutes'
import UserCancellations from './UserCancellations'
// import UserSearches from "./UserSearches";
import UserClaims from './UserClaims'
import UserAccount from './UserAccount'
// import PageNotFound from '../PageNotFound'
import PolicyDetail from './PolicyDetail'
import NewCancellation from './NewCancellation'
import NewClaim from './NewClaim'
import DashboardLayout from '../../components/Layouts/DashboardLayout'
import DashboardOverview from '.'
import MyMotorPolicies from './motor-policies'
import MyTravelPolicies from './travel-policies'
import TravelProduct from './travel-products'
import MyHomePolicies from './property-policies'
import MyHealthPolicies from './health'
import MyLifePolicies from './life'
import Clients from './clients'
import Companies from './companies'
import TransactionRoutes from './transactions'
import UserRoutes from './users'
import Emails from './emails'
import Promos from './promos'

const DashboardRoutes = props => {
  return (
    <DashboardLayout>
      <Switch>
        <PrivateRoute
          exact
          path={props.match.path}
          component={DashboardOverview}
        />
        <PrivateRoute
          path={`${props.match.path}buy-motor-insurance`}
          component={MotorRoutes}
        />
        <PrivateRoute
          path={`${props.match.path}buy-health-insurance`}
          component={HealthRoutes}
        />
        <PrivateRoute
          path={`${props.match.path}buy-life-insurance`}
          component={LifeRoutes}
        />
        <PrivateRoute
          path={`${props.match.path}buy-home-insurance`}
          component={PropertyRoutes}
        />
        <PrivateRoute
          path={`${props.match.path}buy-travel-insurance`}
          component={TravelRoutes}
        />
        <PrivateRoute
          path={`${props.match.path}payments`}
          component={PaymentRoutes}
        />
        <PrivateRoute path={`${props.match.path}clients`} component={Clients} />
        <PrivateRoute
          path={`${props.match.path}companies`}
          component={Companies}
        />
        <PrivateRoute path={`${props.match.path}emails`} component={Emails} />
        <PrivateRoute
          path={`${props.match.path}promo-codes`}
          component={Promos}
        />
        <PrivateRoute
          path={`${props.match.path}transactions`}
          component={TransactionRoutes}
        />
        <PrivateRoute
          path={`${props.match.path}users`}
          component={UserRoutes}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}motor`}
          component={MyMotorPolicies}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}travel`}
          component={MyTravelPolicies}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}travel-products`}
          component={TravelProduct}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}property`}
          component={MyHomePolicies}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}health`}
          component={MyHealthPolicies}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}life`}
          component={MyLifePolicies}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}cancellations`}
          component={UserCancellations}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}cancellations/new/:policyId`}
          component={NewCancellation}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}claims`}
          component={UserClaims}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}claims/travel/new/:policyId`}
          component={NewClaim}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}claims/motor/new/:policyId`}
          component={NewClaim}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}claims/property/new/:policyId`}
          component={NewClaim}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}claims/health/new/:policyId`}
          component={NewClaim}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}claims/life/new/:policyId`}
          component={NewClaim}
        />
        {/* <PrivateRoute
              path={`${props.match.path}/saved`}
              component={UserSearches}
            /> */}
        <PrivateRoute
          path={`${props.match.path}account-settings`}
          component={UserAccount}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}policies/travel/:policyId`}
          component={PolicyDetail}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}policies/motor/:policyId`}
          component={PolicyDetail}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}policies/property/:policyId`}
          component={PolicyDetail}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}policies/health/:policyId`}
          component={PolicyDetail}
        />
        <PrivateRoute
          exact
          path={`${props.match.path}policies/life/:policyId`}
          component={PolicyDetail}
        />

        {/* <Route component={PageNotFound} /> */}
      </Switch>
    </DashboardLayout>
  )
}

DashboardRoutes.propTypes = {
  match: PropTypes.object
}

export default withRouter(DashboardRoutes)
