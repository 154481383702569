import React from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import bqUserIcon from 'img/bq-user-icon.svg'
import WithUser from 'hocs/WithUser'
import { Menu, Dropdown } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faBars } from '@fortawesome/free-solid-svg-icons'

const DashboardNavbar = props => {
  const menu = (
    <Menu>
      {/* <Menu.Item>
        <Link to="/" className="">
          Home Page
        </Link>
      </Menu.Item> */}
      {/* <Menu.Item>
        <Link to="/account/settings" className="">
          My Account
        </Link>
      </Menu.Item> */}
      <Menu.Item onClick={props.unsetUser}>Logout</Menu.Item>
    </Menu>
  )

  return (
    <div className="dashboard_navbar">
      <div className="dashboard_navbar_left" onClick={props.handleOpen}>
        <FontAwesomeIcon icon={faBars} />
      </div>

      <Dropdown overlay={menu} placement="bottomCenter">
        <div
          className="dashboard_navbar_right"
          onClick={() => props.history.push('#')}
        >
          <span>
            <img src={bqUserIcon} alt="user-icon" />{' '}
            {`${props.authUser.user &&
              props.authUser.user.firstName} ${props.authUser.user
              .lastName && props.authUser.user.lastName}`}
          </span>
          <span
            style={{ fontSize: '1.6rem' }}
            className="dashboard_navbar_left_text ml-1rem"
          >
            <FontAwesomeIcon icon={faAngleDown} />
          </span>
        </div>
      </Dropdown>
    </div>
  )
}

DashboardNavbar.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  handleOpen: PropTypes.func
}

export default withRouter(WithUser(DashboardNavbar))
