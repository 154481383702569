import React from 'react'
import { Button, Modal, Breadcrumb, Table, Divider } from 'antd'
// import C3Chart from 'react-c3js'
import axios from 'axios'
// import { connect } from 'react-redux'

import Create from './Create'
import Update from './Update'

import * as pc from 'app-constants/products'
// import { setLoading } from '../../../ducks/app'
import 'c3/c3.css'
import './style.scss'
// import moment from 'moment/moment'
import { travelProductsUrl, travelUrl, getHeaders } from '../../../../config'
import PageLoader from 'components/Layouts/PageLoader'

export const policyColumns = [
  {
    title: 'Product',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Company',
    dataIndex: 'companyName',
    key: 'companyName'
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country'
  },
  {
    title: 'Type',
    dataIndex: 'typeName',
    key: 'type',
    filters: [
      {
        text: 'Motor',
        value: pc.MOTOR
      },
      {
        text: 'Travel',
        value: pc.TRAVEL
      },
      {
        text: 'Property',
        value: pc.PROPERTY
      }
    ],
    onFilter: (value, record) => record.type === value
  },
  {
    title: 'Sold',
    dataIndex: 'sold',
    key: 'sold',
    sorter: (a, b) => a.sold - b.sold
  },
  {
    title: 'Claimed',
    dataIndex: 'claimed',
    key: 'claimed',
    sorter: (a, b) => a.claimed - b.claimed
  },
  {
    title: 'Cancelled',
    dataIndex: 'cancelled',
    key: 'cancelled',
    sorter: (a, b) => a.cancelled - b.cancelled
  },
  {
    title: 'Published',
    dataIndex: 'isApprovedName',
    key: 'isApproved',
    filters: [
      {
        text: 'Yes',
        value: true
      },
      {
        text: 'No',
        value: false
      }
    ],
    onFilter: (value, product) => product.isApproved === value
  }
]

// const mapStateToProps = (state, props) => ({
//   isLoading: state.app.isLoading
// })

// @connect(mapStateToProps)
class TravelProducts extends React.Component {
  state = {
    tableData: [],
    policiesSold: {
      amounts: [
        {
          currency: 'GHS',
          amount: 0
        }
      ],
      number: 0,
      data: [],
      dataByProduct: {}
    },
    productLimit: '7',
    chartType: pc.TRAVEL,
    showModal: false,
    activeProduct: null,
    activeForm: '',
    tableSearchText: '',
    loading: false
  }

  getPolicyStats = () => {
    this.setState({ loading: true })

    axios
      .get(`${travelUrl}/stats/`, {
        headers: getHeaders()
      })
      .then(res => {
        let policiesSold = {
          amounts: res.data.results.totalPoliciesSold.amounts,
          number: res.data.results.totalPoliciesSold.number,
          data: res.data.results.data,
          dataByProduct: {
            ...res.data.results.policiesByName[pc.TRAVEL],
            ...res.data.results.policiesByName[pc.MOTOR],
            ...res.data.results.policiesByName[pc.LIFE]
          }
        }
        this.setState({ policiesSold, loading: false })
      })
      .catch(error => {
        this.setState({ loading: false })
        console.log(error)
      })
  }

  getProductStats = () => {
    this.setState({ loading: true })
    axios
      .get(`${travelProductsUrl}/stats/`, {
        headers: getHeaders()
      })
      .then(res => {
        let tableData = res.data.results.map(product => {
          let newProduct = {}
          newProduct.name = product.name
          newProduct.companyName = product.company.name
          newProduct.country = pc.REGIONAL_CHOICES[product.company.region]
          newProduct.info = product
          newProduct.type = product.type
          newProduct.typeName = pc.PRODUCT_TYPE_CHOICES[product.type]
          newProduct.isApproved = product.isApproved
          newProduct.isApprovedName = product.isApproved ? 'Yes' : 'No'
          newProduct.sold = product.sold
          newProduct.cancelled = product.cancelled
          newProduct.claimed = product.claimed
          newProduct.key = product.id
          return newProduct
        })

        this.setState({ tableData, loading: false })
      })
      .catch(error => {
        console.log(error)
        this.setState({ loading: false })
      })
  }

  updateTableResults() {
    // this.getPolicyStats()
    this.getProductStats()
  }

  componentDidMount() {
    this.updateTableResults()
  }

  onSearch = e => {
    this.setState({ tableSearchText: e.target.value })
  }

  handleAddProduct = () => {
    this.setState({
      activeForm: 'Add Product',
      showModal: true
    })
  }

  handleEditProduct = record => {
    this.setState({
      activeProduct: record.info,
      activeForm: 'Edit Product',
      showModal: true
    })
    console.log(record)
  }

  handleViewProduct = record => {
    this.setState({
      activeProduct: record.info,
      activeForm: 'View Product',
      showModal: true
    })
  }

  handleDeleteProduct = record => {
    this.setState({
      activeProduct: record.info,
      activeForm: 'Delete Product',
      showModal: true
    })
  }

  handleOk = update => {
    this.setState(
      {
        showModal: false,
        activeForm: ''
      },
      () => {
        if (update) this.updateTableResults()
      }
    )
  }

  handleCancel = e => {
    this.setState({
      showModal: false,
      activeForm: ''
    })
  }

  handleOnChange = pagination => {
    console.log(pagination)
  }

  downloadProducts = event => {
    event.preventDefault()

    let csvContent = 'data:text/csv;charset=utf-8,'
    let headers = 'Product, Company, Type, Sold, Claimed, Cancelled, IsApproved'
    csvContent += headers + '\r\n'
    for (let i = 0; i < this.state.tableData.length; i++) {
      let product = this.state.tableData[i]
      let row = `${product.name}, ${product.companyName}, ${product.typeName}, ${product.sold}, ${product.claimed}, ${product.cancelled}, ${product.isApprovedName}`
      csvContent += row + '\r\n'
    }
    let encodedUri = encodeURI(csvContent)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'products.csv')
    document.body.appendChild(link) // Required for FF

    link.click()
  }

  printPage = event => {
    event.preventDefault()

    window.print()
  }

  handleInputChange = stateName => event => {
    this.setState({ [stateName]: event.target.value })
  }

  render() {
    if (this.props.isLoading) return null
    let policyForms = {
      'Add Product': (
        <Create handleCancel={this.handleCancel} handleOk={this.handleOk} />
      ),
      'Edit Product': (
        <Update
          product={this.state.activeProduct}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
        />
      ),
      'View Product': (
        <Update
          product={this.state.activeProduct}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          isDisabled={true}
        />
      ),
      'Delete Product': (
        <Update
          product={this.state.activeProduct}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          isDelete={true}
        />
      )
    }

    // eslint-disable-next-line
    const bestsellingColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type'
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company'
      }
    ]

    let orderedDataByProduct = {}
    let unordered = this.state.policiesSold.dataByProduct
    Object.keys(unordered)
      .sort(function(a, b) {
        return unordered[b].productCount - unordered[a].productCount
      })
      .forEach(function(key) {
        orderedDataByProduct[key] = unordered[key]
      })

    // eslint-disable-next-line
    let bestSelling = Object.values(orderedDataByProduct)
    bestSelling = bestSelling.slice(0, parseInt(this.state.productLimit, 10))
    bestSelling = bestSelling.map(item => ({ ...item, key: item.productId }))

    const localPolicyColumns = [
      ...policyColumns,
      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            {/* eslint-disable-next-line */}
            <a href="#" onClick={() => this.handleViewProduct(record)}>
              View
            </a>
            <Divider type="vertical" />
            {/* eslint-disable-next-line */}
            <a href="#" onClick={() => this.handleEditProduct(record)}>
              Edit
            </a>
            <Divider type="vertical" />
            {/* eslint-disable-next-line */}
            <a href="#" onClick={() => this.handleDeleteProduct(record)}>
              Delete
            </a>
          </span>
        )
      }
    ]

    let colors = {
      _primary: '#01a8fe',
      _default: '#acb7bf',
      _success: '#46be8a',
      _danger: '#fb434a'
    }

    let timeColumn = Object.keys(this.state.policiesSold.data)
    timeColumn = ['x', ...timeColumn]

    let allColumns = Object.values(this.state.policiesSold.data)

    let motorColumn = allColumns.map(point => point[pc.MOTOR])
    motorColumn = ['Motor', ...motorColumn]

    let travelColumn = allColumns.map(point => point[pc.TRAVEL])
    travelColumn = ['Travel', ...travelColumn]

    let propertyColumn = allColumns.map(point => point[pc.LIFE])
    propertyColumn = ['Property', ...propertyColumn]

    let columns = []
    let types = {}
    switch (this.state.chartType) {
      case pc.TRAVEL:
        columns = [timeColumn, travelColumn]
        types = { Travel: 'area-spline' }
        break
      case pc.MOTOR:
        columns = [timeColumn, motorColumn]
        types = { Motor: 'area-spline' }
        break
      case pc.PROPERTY:
        columns = [timeColumn, propertyColumn]
        types = { Property: 'area-spline' }
        break
      default:
        columns = [timeColumn, travelColumn, motorColumn, propertyColumn]
        types = {
          Travel: 'area-spline',
          Motor: 'area-spline',
          Life: 'area-spline'
        }
    }

    // eslint-disable-next-line
    let area = {
      data: {
        x: 'x',
        columns: columns,
        types: types
      },
      color: {
        pattern: [colors._primary, colors._success]
      },
      axis: {
        x: {
          type: 'timeseries',
          tick: {
            format: '%b, %d',
            count: 20,
            fit: true
          }
        }
      }
    }

    const tableSearchText = this.state.tableSearchText
    let tableData = this.state.tableData
    if (tableSearchText) {
      tableData = tableData.filter(
        product =>
          product.name.includes(tableSearchText) ||
          product.info.company.name.includes(tableSearchText)
      )
    }

    return (
      <PageLoader className="row pl-4rem pr-4rem" loading={this.state.loading}>
        <div className="row">
          <Breadcrumb>
            <Breadcrumb.Item href="/">
              {/* <HomeOutlined /> */}
              <span>Home</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Travel Products</Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          {/* <div className="col-lg-12">
            <span className="pull-right mb-4">
              <span className="mr-3">
                <Button onClick={this.printPage}>Print Report</Button>
              </span>
              <Button type="primary" onClick={this.downloadProducts}>
                Download File
              </Button>
            </span>
          </div> */}
          {/* <div className="col-lg-6">
            <div className="card">
              <div className="card-header">
                <span className="utils__title">Product Performance</span>
                <span className="pull-right">
                  <select
                    className="custom-select"
                    value={this.state.chartType}
                    onChange={this.handleInputChange('chartType')}
                  >
                    {Object.keys(pc.PRODUCT_TYPE_CHOICES).map(choice => (
                      <option key={choice} value={choice}>
                        {pc.PRODUCT_TYPE_CHOICES[choice]}
                      </option>
                    ))}
                  </select>
                </span>
              </div>
              <div className="card-body">
                <C3Chart
                  data={area.data}
                  color={area.color}
                  axis={area.axis}
                  unloadBeforeLoad={true}
                />
              </div>
            </div>
          </div> */}

          {/* <div className="col-lg-6">
            <div className="card">
              <div className="card-header">
                <span className="utils__title">
                  Bestselling products on BestQuote
                </span>
                <span className="pull-right">
                  <select
                    className="custom-select"
                    value={this.state.productLimit}
                    onChange={this.handleInputChange('productLimit')}
                  >
                    <option value="5">Top 5</option>
                    <option value="10">Top 10</option>
                    <option value="20">Top 20</option>
                  </select>
                </span>
              </div>
              <div className="card-body">
                <Table
                  columns={bestsellingColumns}
                  dataSource={bestSelling}
                  pagination={false}
                />
              </div>
            </div>
          </div> */}

          <Modal
            title={this.state.activeForm}
            centered
            visible={this.state.showModal}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
            footer={null}
          >
            {this.state.showModal ? policyForms[this.state.activeForm] : null}
          </Modal>

          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <div
                  className=""
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span className="pull-left">
                    <span>Travel Products</span>
                    {/* <Input.Search
                      onChange={this.onSearch}
                      placeholder="Search Products"
                      value={this.state.tableSearchText}
                      onPressEnter={this.onSearch}
                    /> */}
                  </span>
                  <span className="pull-right">
                    <Button type="primary" onClick={this.handleAddProduct}>
                      Add New Product
                    </Button>
                  </span>
                </div>
              </div>
              <div className="card-body">
                <Table columns={localPolicyColumns} dataSource={tableData} />
              </div>
            </div>
          </div>
        </div>
      </PageLoader>
    )
  }
}

export default TravelProducts
