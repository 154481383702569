import React from 'react'
import { Space, Modal, Breadcrumb } from 'antd'
import axios from 'axios/index'
import 'c3/c3.css'
import './style.scss'
import Detail from './Detail'
import { companiesUrl, getHeaders } from '../../../../config'
import { withRouter } from 'react-router-dom'
import FilterTable from 'components/Forms/searchFilter'
import PageLoader from 'components/Layouts/PageLoader'

class Company extends React.Component {
  state = {
    companyTableData: [],
    showCompanyModal: false,
    selectedCompany: null,
    loading: false
  }

  // update tables
  updateTableResults() {
    // const { dispatch } = this.props
    this.setState({ loading: true })
    axios
      .get(companiesUrl, { headers: getHeaders() })
      .then(res => {
        this.setState({ loading: false })
        // motor request
        let companies = res.data.results.map(dep => {
          return {
            ...dep,
            agreement: dep.agreementAccepted ? 'YES' : 'NO',
            createdAt: new Date(dep.createdOn).toDateString()
          }
        })
        // end motor request

        this.setState({
          companyTableData: companies
        })
        console.log('DATA SET')
      })
      .catch(error => {
        this.setState({ loading: false })
        console.log(error)
      })
    // .then(() => {
    //   // dispatch(setLoading(false));
    // })
  }
  // end update tables

  componentDidMount() {
    this.updateTableResults()
  }

  handleShowCompanyModal = () => {
    console.log('Show modal')
    this.setState({
      showCompanyModal: true
    })
  }

  handleCompanyOk = e => {
    console.log(e)
    this.setState({
      showCompanyModal: false
    })
  }

  handleCompanyCancel = e => {
    console.log(e)
    this.setState(
      {
        showCompanyModal: false
      },
      () => this.updateTableResults()
    )
  }

  handleInputChange = stateName => event => {
    this.setState({ [stateName]: event.target.value })
  }

  render() {
    if (this.props.isLoading) return null

    let companyDetailRow = record => {
      this.setState({ selectedCompany: record, showCompanyModal: true })
    }

    let { companyTableData } = this.state

    const columns = [
      {
        title: 'Date Added',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 120
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 120
      },
      {
        title: 'Email Address',
        dataIndex: 'email',
        key: 'email',
        width: 150
        // fixed: 'left'
      },
      {
        title: 'Office Phone',
        dataIndex: 'officePhone',
        key: 'officePhone',
        width: 120
      },
      {
        title: 'Office Address',
        dataIndex: 'officeAddress',
        key: 'officeAddress',
        width: 120
      },

      {
        title: 'Agreement Accepted',
        dataIndex: 'agreement',
        key: 'agreement',
        width: 90
      },

      {
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 80,
        render: (text, record) => (
          <Space size="middle">
            <span style={{ color: 'blue', cursor: 'pointer' }}>View</span>
          </Space>
        )
      }
    ]

    return (
      <PageLoader className="row main-page" loading={this.state.loading}>
        <Breadcrumb>
          <Breadcrumb.Item href="/">
            {/* <HomeOutlined /> */}
            <span>Home</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Companies</Breadcrumb.Item>
        </Breadcrumb>
        <br />
        <br />
        <Modal
          title="Company Details"
          centered
          visible={this.state.showCompanyModal}
          onOk={this.handleCompanyOk}
          onCancel={this.handleCompanyCancel}
          footer={null}
        >
          {this.state.showCompanyModal ? (
            <Detail
              handleCancel={this.handleCompanyCancel}
              company={this.state.selectedCompany}
            />
          ) : null}
        </Modal>

        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <div className="utils__title">
                <span>All Companies</span>
              </div>
            </div>
            <div className="card-body">
              <div style={{ marginBottom: 16 }}>
                {/* <button
                  className="btn_normal btn_gradient btn_md"
                  onClick={() =>
                    this.props.history.push('/buy-motor-insurance')
                  }
                >
                  Buy New Policy
                </button> */}
              </div>

              <FilterTable
                columns={columns}
                dataSource={companyTableData}
                onRow={companyDetailRow}
              />
            </div>
          </div>
        </div>
      </PageLoader>
    )
  }
}

export default withRouter(Company)
