import React, { useEffect, useState } from 'react'
import { Breadcrumb, Modal } from 'antd'
import FilterTable from 'components/Forms/searchFilter'
import axios from 'axios'

import { getHeaders, errorNotification, userAccountsUrl } from '../../../config'
import PageLoader from 'components/Layouts/PageLoader'
import Detail from './Detail'

const Home = props => {
  const [users, setUsers] = useState([])

  // eslint-disable-next-line
  const [meta, setMeta] = useState({})
  const [loading, setloading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [current, setCurrent] = useState(null)

  const getUser = () => {
    setloading(true)
    axios
      .get(userAccountsUrl, { headers: getHeaders() })
      .then(res => {
        setloading(false)
        setUsers(
          res.data.results.map(user => {
            return {
              ...user,
              fullName: `${user.firstName} ${user.lastName}`,
              email: user.email,
              status: user.isActive ? 'ACTIVE' : 'INACTIVE',
              isStaff: user.isStaff ? 'YES' : 'NO',
              isAffiliate: user.isAffiliate ? 'YES' : 'NO',
              affiliateVerified: user.affiliateVerified ? 'YES' : 'NO',
              isCompanyAffiliate: user.isCompanyAffiliate ? 'YES' : 'NO',
              affiliateCompany: user.affiliateCompany
                ? user.affiliateCompany.name
                : 'N/A',
              dateJoined: `${new Date(
                user.dateJoined
              ).toDateString()} | ${new Date(user.dateJoined).toTimeString()}`,
              lastLogin: `${new Date(
                user.lastLogin
              ).toDateString()} | ${new Date(user.lastLogin).toTimeString()}`
            }
          })
        )
        setMeta({
          previous: res.data.previous,
          next: res.data.next,
          total: res.data.count
        })
        // console.log(res.data)
      })
      .catch(err => {
        setloading(false)
        errorNotification('Error fetching transactions')
      })
  }

  useEffect(() => {
    getUser()
  }, [])

  const columns = [
    {
      title: 'Full Name',
      dataIndex: 'fullName',
      key: 'fullName',
      width: 120
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 120
    },
    {
      title: 'Account Status',
      dataIndex: 'status',
      key: 'status',
      width: 120
    },
    {
      title: 'Admin Status',
      dataIndex: 'isStaff',
      key: 'isStaff',
      width: 120
    },
    {
      title: 'Affiliate Status',
      dataIndex: 'isAffiliate',
      key: 'isAffiliate',
      width: 120
    },
    {
      title: 'Affiliate Verified',
      dataIndex: 'affiliateVerified',
      key: 'affiliateVerified',
      width: 120
    },
    // {
    //   title: 'Company',
    //   dataIndex: 'companyName',
    //   key: 'companyName',
    //   width: 120,
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <span>{record.companyName}</span>
    //     </Space>
    //   )
    // },
    {
      title: 'Date Joined',
      dataIndex: 'dateJoined',
      key: 'dateJoined',
      width: 120
    },
    {
      title: 'Last Logged In',
      dataIndex: 'lastLogin',
      key: 'lastLogin',
      width: 120
    }
    // {
    //   title: 'Action',
    //   key: 'action',
    //   fixed: 'right',
    //   width: 80,
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <span onClick={handleDetail.bind(this, record)}>View</span>

    //       <a href="#" onClick={handleDetail.bind(this, record)}>
    //         View
    //       </a>
    //     </Space>
    //   )
    // }
  ]

  const handleCurrent = curr => {
    setShowModal(true)
    setCurrent(curr)
  }

  return (
    <PageLoader className="container" loading={loading}>
      <div className="row">
        <div className="col-lg-12">
          <Breadcrumb>
            <Breadcrumb.Item>
              <span>Dashboard</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span>Users</span>
            </Breadcrumb.Item>
          </Breadcrumb>
          <br />
          <br />
          <div className="card mb-4rem">
            <div className="card-header">
              <div className="utils__title">
                <span>User Accounts</span>
              </div>
            </div>

            <div className="card-body">
              <FilterTable
                columns={columns}
                dataSource={users}
                onRow={handleCurrent}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="User Details"
        centered
        visible={showModal}
        onOk={() => {
          setShowModal(false)
          setCurrent(null)
        }}
        onCancel={() => {
          setShowModal(false)
          setCurrent(null)
        }}
        footer={null}
      >
        {showModal && current ? <Detail user={current} /> : null}
      </Modal>
    </PageLoader>
  )
}

export default Home
